import React from 'react';

// Import assets
import { ReactComponent as IconCastSVG } from 'assets/icons/ic_cast.svg';
import { ReactComponent as IconCastConnectedSVG } from 'assets/icons/ic_cast_connected.svg';

// Import components
import { Button } from 'components/elements';

// Import context
import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';

const CastButton = () => {
	const { isCasting, isCastAvailable, cast, isReady } = useContextProvider();

	if (!isCastAvailable || !isReady) return null;

	return (
		<Button onClick={cast} modifiers={['icon', 'playerIconHover']}>
			{isCasting ? <IconCastConnectedSVG /> : <IconCastSVG />}
		</Button>
	);
};

export default CastButton;
