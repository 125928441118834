import { colors } from './colors';
import { fonts } from '../fonts';
import { buttons } from './buttons';

export const wideKhalijiTheme = {
	...colors,
	...fonts,
	...buttons,
	darkMode: true,
	grayMedium: colors.grayMediumDarkTheme,
	general: {
		body: `linear-gradient(108.69deg, #0C0C0C 0%, #1D1D1D 100%)`,
		html: colors.grayPrimary,
		linkHover: colors.white
	},
	buttonDefault: {
		bg: colors.primary,
		text: colors.white,
		border: colors.primary,
		color: colors.white
	},
	buttonPrimary: {
		bg: colors.black,
		color: colors.white,
		border: `linear-gradient(
			110.42deg,
			#b07f27 26.61%,
			#e6cf5d 48.71%,
			#b07f27 71.75%
		)`,
		bgHover: colors.primary
	},
	buttonRounded: {
		color: colors.white,
		roundSize: 5,
		border: colors.primary,
		bg: colors.grayPrimary
	},
	buttonTransparent: {
		color: colors.white,
		roundSize: 0.4,
		border: `linear-gradient(
			110.42deg,
			#b07f27 26.61%,
			#e6cf5d 48.71%,
			#b07f27 71.75%
		)`,
		bg: colors.black,
		bgSecond: `linear-gradient(136.81deg, rgba(176, 127, 39, 0.8) 21.97%, rgba(230, 207, 93, 0.8) 50.38%, rgba(176, 127, 39, 0.8) 85.34%)`,
		shadowSecondHover: '0px 0px 15px #bf9630, 0px 0px 50px rgba(0, 0, 0, 0.7)',
		bgSecondHover: `linear-gradient(136.81deg, #B07F27 21.97%, #E6CF5D 50.38%, #B07F27 85.34%)`,
		bgHover: colors.primary
	},
	loader: {
		dotted: {
			bg: colors.primary
		}
	},
	heading: {
		first: {
			white: colors.white,
			primary: colors.white
		}
	},
	line: '#2d343d',
	border: colors.primary,
	dashedLine: colors.grayMediumDarkTheme,
	activeGradientLine: {
		colorStart: colors.primary,
		colorEnd: colors.primaryDark,
		tvsmart: {
			colorStart: '#FFEB00',
			colorMiddle: '#EAB036',
			colorEnd: '#CB931E'
		}
	},
	loaderBg: 'transparent',
	body: {
		bg: `linear-gradient(108.69deg, #0C0C0C 0%, #1D1D1D 100%)`
	},
	header: {
		bg: colors.grayPrimary,
		shadow: '0 2px 6px 0 rgba(0, 0, 0, 0.18)',
		icon: '$F8F8F9',
		iconActive: colors.white,
		separateLine: colors.grayMediumDarkTheme
	},
	navSearch: {
		bg: colors.grayPrimary,
		inputBg: 'transparent',
		inputColor: colors.grayMediumDarkTheme,
		inputBorder: '#2d343d',
		inputBorderFocus: colors.primary,
		dashedLine: '#fff'
	},
	navSearchCloseIcon: colors.grayMediumDarkTheme,
	searchBox: {
		bg: colors.grayPrimary,
		itemHoverBg: colors.primary,
		itemInfo: '#95A1AF',
		itemInfoHover: colors.white,
		itemTitleHover: colors.white,
		shadow: '0 0 20px rgba(0, 0, 0, 0.15)',
		border: colors.primary,
		name: colors.primary,
		emptyContent: colors.semiWhite,
		linkColor: colors.grayMediumDarkTheme,
		linkColorHover: colors.primary
	},
	searchPage: {
		searchBar: {
			bg: colors.black,
			input: {
				label: colors.primary,
				color: colors.white,
				bg: colors.black,
				placeholder: colors.white
			}
		},
		searchNav: {
			activeItemBorder: colors.primary
		},
		searchItems: {
			bg: 'linear-gradient(108.69deg,#0C0C0C 0%,#1D1D1D 100%)',
			tag: {
				color: 'rgba(226, 226, 226, 1)',
				bg: `linear-gradient(
					95.49deg,
					rgba(176, 127, 39, 0.7) 3.7%,
					rgba(230, 207, 93, 0.608968) 100%
				)`,
				roundSize: 0.3
			},
			border: `linear-gradient(180deg, #B07F27 0%, rgba(230, 207, 93, 0.869954) 100%)`,
			shadowHover: `drop-shadow(0px 0px 15px #bf9630)
			drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.7))`,
			titleColor: colors.white
		}
	},
	searchItem: {
		separatingLine: colors.primary,
		borderHover: colors.primary
	},
	nav: {
		height: 7,
		menuActiveText: colors.primary,
		color: '#F8F8F9',
		loginButton: {
			bg: colors.grayPrimary
		},
		dropdown: {
			item: colors.white
		}
	},
	footer: {
		bg: '#202020',
		height: 21.8,
		color: '#F8F8F9',
		link: '#F8F8F9',
		divider: colors.primary,
		socialBg: `linear-gradient(
			136.49deg,
			#b07f27 22.28%,
			#e6cf5d 49.68%,
			#b07f27 76.16%
		)`,
		payment: {
			bg: colors.black,
			border: ` linear-gradient(
				110.42deg,
				#b07f27 26.61%,
				#e6cf5d 48.71%,
				#b07f27 71.75%
			)`,
			roundSize: 0.4
		},
		store: {
			bg: colors.black,
			border: `linear-gradient(
				110.42deg,
				#b07f27 26.61%,
				#e6cf5d 48.71%,
				#b07f27 71.75%
			)`,
			roundSize: 0.4
		}
	},
	login: {
		methods: {
			bg: `linear-gradient(
				119.52deg,
				#202020 1.22%,
				rgba(77, 77, 77, 0.6) 98.7%
			)`,
			border: `rgba(226, 226, 226, 0.29)`,
			roundSize: 1.6
		},
		authButton: {
			roundSize: 0.7,
			border: `linear-gradient(
				110.42deg,
				#b07f27 26.61%,
				#e6cf5d 48.71%,
				#b07f27 71.75%
			)`
		},
		phoneAuth: {
			field: {
				border: colors.primary,
				color: colors.white,
				borderFocus: colors.white,
				roundSize: 0.4
			},
			dialCode: colors.white,
			authPhoneNumber: {
				bg: 'rgba(0, 0, 0, 0.16)',
				border: `rgba(176, 127, 39, 0.2)`
			},
			otherMethodsInfo: {
				color: '#E2E2E2',
				link: '#fff'
			},
			resendCode: {
				button: { color: colors.semiWhite },
				time: colors.primary
			},
			numberError: {
				bg: 'rgba(255, 206, 206, 1)',
				color: '#b50000'
			},
			modalError: {
				color: colors.white
			}
		},

		footerText: 'rgba(226, 226, 226, 0.5)'
	},
	nonSubscriber: {
		box: {
			bg: `linear-gradient(
				119.52deg,
				#202020 1.22%,
				rgba(77, 77, 77, 0.6) 98.7%
			)`,
			border: 'rgba(226, 226, 226, 0.29)',
			roundSize: 1.6,
			button: {
				subscribeBg: `linear-gradient(
					136.81deg,
					rgba(176, 127, 39, 0.8) 21.97%,
					rgba(230, 207, 93, 0.8) 50.38%,
					rgba(176, 127, 39, 0.8) 85.34%
				)`,
				subscribeShadow: 'rgba(0, 0, 0, 0.55)',
				roundSize: 0.7
			}
		}
	},

	sidebar: {
		bg: colors.grayPrimary,
		itemHoverBg: colors.primary,
		removeIcon: colors.grayMediumDarkTheme,
		removeIconHover: colors.white,
		closeIcon: colors.grayMediumDarkTheme,
		favouriteButton: {
			color: colors.white,
			colorActive: colors.primary
		}
	},
	player: {
		topHeadline: {
			color: colors.white
		},
		slider: {
			backgroundColor: colors.primary,
			strap: colors.whiteTransparent03,
			dot: colors.primary
		},
		sidebar: {
			itemBorderActive: `linear-gradient(
				90deg,
				#b07f27 26.61%,
				rgba(217, 217, 217, 0) 102.63%
			);`
		},
		volume: {
			track: colors.whiteSemiTranslucent,
			thumb: colors.white
		},
		timer: {
			color: colors.white
		},
		settings: {
			borderColor:
				'linear-gradient(90deg, rgba(176,127,39,1) 0%, rgba(230,207,93,0.87) 100%);',
			backgroundColor:
				'linear-gradient(90deg,	rgba(15, 15, 17, 1) 0%,	rgba(44, 44, 49, 1) 100%);',
			TVBackgroundColor: colors.blackTranslucent04,
			profile: {
				titleColor: colors.semiWhite,
				color: colors.semiWhite,
				borderTopColor: colors.semiWhite05,
				selected: colors.primary,
				hover: colors.primary
			}
		},
		recommendations: {
			header: {
				color: colors.whiteGrayLight07
			},
			item: {
				title: {
					color: colors.whiteGrayLight09
				}
			},
			slider: {
				border: '1px solid #E6CF5D',
				backgroundColor: colors.graySemiDark,
				shadow: 'drop-shadow(0px 0px 8px #bf9630)'
			}
		},
		nextEpisode: {
			heading: 'rgba(248, 248, 249, 0.9)',
			title: colors.white,
			timerText: 'rgba(248, 248, 249, 0.9)',
			cover: {
				border: `linear-gradient(156.32deg, #B07F27 0%, #E6C95D 51.04%, #B07F27 100%)`,
				shadow: '0px 0px 15px #BF9630'
			}
		},
		liveIndicator: {
			dot: {
				active: colors.darkRed,
				inactive: colors.grayMain
			},
			color: colors.white
		},
		cast: {
			name: {
				color: '#99999a',
				backgroundColor: '#12100b',
				roundSize: 0.6
			}
		}
	},
	playerTv: {
		button: {
			color: '#999999',
			colorHover: colors.white
		},
		heading: {
			title: colors.white,
			duration: '#999999',
			bg: 'linear-gradient(180deg, #000000 17.45%, rgba(0, 0, 0, 0.01) 92.09%);'
		},
		controllers: {
			bg: 'linear-gradient(360deg,#000000 17.45%,rgba(0, 0, 0, 0.01) 92.09%);'
		},
		sidebar: {
			width: 40.4,
			height: {
				default: '100%',
				fullscreen: 'calc(100% - 102px)'
			},
			background: {
				default: 'rgba(0, 0, 0, 1)',
				fullscreen: 'transparent'
			},
			scrollbar: {
				thumb: 'rgba(113, 113, 113, 1)',
				track: 'transparent'
			},
			programTile: {
				color: colors.white,
				background: 'linear-gradient(130.14deg, #1e1e24 0%, #2c2c31 100%)',
				backgroundHover: 'rgba(105, 105, 105, 0.6)',
				borderActive: 'rgba(183, 151, 68, 1)'
			},
			search: {
				color: colors.white,
				placeholder: colors.grayMediumDarkTheme,
				background: 'rgba(54, 54, 54, 1)'
			},
			filters: {
				color: colors.white,
				background: {
					default: 'rgba(54, 54, 54, 1)',
					active: 'rgba(183, 151, 68, 1)'
				}
			},
			watchlist_modal: {
				bg: 'linear-gradient(130.14deg, #1e1e24 0%, #2c2c31 100%);',
				border:
					'linear-gradient(110.42deg, #B07F27 26.61%, #E6CF5D 48.71%, #B07F27 71.75%)',
				description: '#e2e2e2',
				checkbox: {
					border: {
						default: '#B3B3B3',
						active: '#b79744'
					},
					bg: {
						default: 'transparent',
						active: '#b79744'
					},
					label: '#ffffff',
					icon_checked: '#FFFFFF'
				},
				closeButton: '#ffffff'
			}
		}
	},
	popover: {
		bg: colors.grayPrimary,
		border: '#2d343d',
		icon: colors.grayMediumDarkTheme,
		iconActive: colors.primary,
		buttonCatchup: colors.primary,
		preview: {
			bg: '#151515'
		}
	},
	button: {
		primaryVod: {
			color: colors.white,
			beforeBgColor1: colors.primaryDark,
			beforeBgColor2: '#d4594f'
		},
		parental: {
			bgColor1: '#3182b5',
			bgColor2: '#2f599c'
		}
	},
	dropdown: {
		button: {
			bg: 'transparent',
			color: colors.white,
			colorHover: colors.white
		},
		menu: {
			bg: colors.grayPrimary,
			itemColor: colors.grayMediumDarkTheme,
			itemHoverColor: colors.white,
			itemSelected: colors.primary,
			itemHoverBg: `linear-gradient(91.55deg, #AB7F33 0%, rgba(171, 127, 51, 0) 100%)`,
			itemHoverBgRtl: `linear-gradient(-91.55deg, #AB7F33 0%, rgba(171, 127, 51, 0) 100%)`,
			itemDivider: `rgba(187, 187, 187, 0.6)`
		}
	},
	buttonLink: {
		color: '#F8F8F9',
		hoverColor: colors.primary
	},
	carousel: {
		bg: `linear-gradient(108.69deg, #0C0C0C 0%, #1D1D1D 100%)`
	},
	modal: {
		bg: colors.grayPrimary,
		titleColor: colors.grayMediumDarkTheme
	},
	catchup: {
		bg: colors.primary,
		color: colors.white
	},
	channels: {
		gridItemLinkHover: colors.white,
		bg: '#2f2f35'
	},
	epg: {
		grayLight: '#fbfafc',
		bg: colors.grayPrimary,
		bgHoverColorStart: `#e6cf5d`,
		bgHoverColorEnd: colors.primary,
		gridItemShadow: '#080C12',
		gridItemCategory: '#95A1AF',
		catchup: {
			bg: 'linear-gradient(to right, #2b323e 0, #2b323e)',
			bgHover: 'linear-gradient(to right, #e6cf5d 0, #b07f27)'
		},
		calendarHover: colors.white
	},
	calendar: {
		bg: colors.grayPrimary,
		date: colors.white,
		selectedDay: colors.primary
	},
	banner: {
		color: colors.white,
		bg: `linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.9) 100%
		)`
	},
	programDetails: {
		favouriteButton: {
			color: colors.gray,
			colorActive: colors.primary
		},
		heading: colors.white,
		paragraph: '#e6e3df',
		name: '#808181',
		table: {
			row: {
				bg: '#440f0b',
				hoverBg: colors.primary
			}
		}
	},
	vodDetails: {
		tabs: {
			color: colors.white,
			border: `linear-gradient(90deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 102.63%);`,
			borderActive: `linear-gradient(
				90deg,
				#b07f27 26.61%,
				rgba(217, 217, 217, 0) 102.63%
			);`
		},
		episodes: {
			card: {
				border: `linear-gradient(156.32deg, #B07F27 0%, #E6C95D 51.04%, #B07F27 100%)`,
				title: colors.white,
				description: colors.white,
				shadow: `drop-shadow(0px 0px 3px #BF9630) drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.7))`,
				shadowBg: `rgba(0, 0, 0, 0.58)`,
				roundSize: 0.3
			}
		},
		casts: {
			color: '#BF9630'
		},
		continueWatching: {
			bg: `linear-gradient(
				90.11deg,
				rgba(176, 127, 39, 0.2) 0%,
				rgba(230, 207, 93, 0.173991) 100%
			)`,
			strapBg: `linear-gradient(
				90.11deg,
				#b07f27 0%,
				rgba(230, 207, 93, 0.869954) 100%
			)`,
			roundSize: 0.5
		},
		moviePoster: {
			border: `linear-gradient(156.32deg, #B07F27 0%, #E6C95D 51.04%, #B07F27 100%);`,
			shadow: `drop-shadow(0px 0px 3px #BF9630) drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.7))`,
			roundSize: 0.3
		}
	},
	user: {
		line: '#2d343d',
		topHeading: '#F3F3F3',
		topHeadingFilter: colors.grayMediumDarkTheme,
		tableTitle: colors.grayMediumDarkTheme,
		tableItem: '#6C7076',
		tableItemName: colors.primary,
		tableItemNameHover: colors.white,
		menuText: colors.white,
		menuBG: colors.transparent,
		menuActiveText: colors.white,
		menuActiveBg: colors.primary,
		showChannel: colors.grayMediumDarkTheme,
		removeIcon: colors.grayMediumDarkTheme,
		removeIconHover: colors.white,
		tags: {
			vodInProfile: colors.blueDark
		},
		productItem: {
			title: colors.white,
			info: '#aaadb2'
		},
		background:
			'linear-gradient( 123.47deg, #202020 0.4%, rgba(32, 32, 32, 0.8) 100%)',
		border: '0.5px solid rgba(243, 243, 243, 0.5)',
		sidebar: {
			border: '0.5px solid rgba(243, 243, 243, 0.2)'
		},
		general: {
			text: '#B3B3B3',
			listBorderColor:
				'linear-gradient(90.41deg, #B07F27 0%, #E6CF5D 43.53%, #BF9536 99.5%)'
		},
		devices: {
			limitColor: 'rgba(243, 243, 243, 0.3)',
			textColor: colors.grayMediumDarkTheme,
			nameColor: colors.primary,
			platformName: colors.white
		},
		watchlist: {
			infoEmpty: '#F3F3F3',
			track: colors.transparent,
			thumb: 'rgba(255, 255, 255, 0.05)',
			item: {
				backgroundColor: '#1d1d1d',
				color: colors.white,
				hoverGradient:
					'linear-gradient(0deg, #1d1d1d 5%, rgba(0, 0, 0, 0) 50%,rgba(0, 0, 0, 0) 100%)',
				border: '1px solid #E6CF5D',
				shadow: 'drop-shadow(0px 5px 5px #bf9630)',
				backgroundColorChannel: `linear-gradient(132.42deg, #1e1e24 7.11%, #2c2c31 94.58%)`
			}
		},
		entry_tickets: {
			label: {
				color: '#f3f3f3'
			},
			field: {
				roundSize: 0.4,
				border: colors.primary,
				color: colors.white
			},
			input: {
				color: colors.white,
				border: 'rgba(255, 255, 255, 0.4)'
			}
		},
		deleting_account: {
			confirmationText: '#f3f3f3'
		},
		subscription: {
			expirationColor: colors.semiWhite,
			expirationDateColor: colors.primary
		},
		subscriptions: {
			defaultTextColor: '#f3f3f3b3',
			priceColor: colors.primary,
			gatewayColor: colors.primary,
			benefitsHeading: '#F3F3F3',
			subscribeBg: colors.black
		},
		device_login: {
			label: {
				color: 'rgba(243, 243, 243, 0.3)'
			},
			field: {
				roundSize: 0.4,
				border: `linear-gradient(110.42deg, #B07F27 26.61%, #E6CF5D 48.71%, #B07F27 71.75%)`
			},
			input: {
				border: 'rgba(255, 255, 255, 0.4)',
				color: colors.primary,
				colorError: colors.red,
				colorSuccess: colors.green
			}
		}
	},
	allMovies: {
		breadcrumbs: {
			color: 'rgba(255, 255, 255, 0.7)'
		},
		menuButton: {
			color: '#636363'
		}
	},
	authentication: {
		contentBg: '#2A2A2A',
		headingColor: colors.white,
		infoColor: colors.whiteTransparent07,
		linkColor: '#F8F8F9',
		linkHoverColor: colors.graySoft,
		lineColor: colors.blackGray,
		agreements: {
			color: colors.whiteTransparent07,
			checkboxBorderColor: colors.graySoft,
			checkboxIconColor: colors.graySoft,
			obligatoryPrefixColor: colors.whiteTransparent07
		},
		input: {
			color: '#858585',
			borderColor: '#2C343D',
			focusBorderColor: colors.orangeNormal,
			errorBorderColor: colors.red,
			errorShadowColor: `inset 0 1px 1px rgba(0, 0, 0, 0.075),
			0 0 8px rgba(239, 32, 82, 0.6)`
		},
		select: {
			border: colors.primary,
			error: colors.red
		},
		firebase_auth_field: {
			border: colors.primary,
			underlining: 'rgba(255, 255, 255, 0.4)',
			color: colors.white,
			errorUnderlining: colors.redSecondary,
			error: colors.redSecondary
		},
		loginTabs: {
			color: '#dadada80',
			checkedColor: colors.graySoft,
			borderColor: '#242830'
		},
		modal: {
			title: colors.grayMediumDarkTheme
		}
	},
	error: colors.red,
	availability_marks: {
		bg_primary: `linear-gradient(
			132.42deg,
			#b07f27 7.11%,
			rgba(230, 207, 93, 0.869954) 94.58%
		);`,
		bg_secondary: `linear-gradient(
			132.42deg,
			rgba(176, 127, 39, 0.8) 7.11%,
			rgba(230, 207, 93, 0.695964) 94.58%
		);`
	},
	slider: {
		name: '#BF9630',
		arrow: {
			color: '#d0c0bf',
			sliderControllerBgColor: 'transparent',
			sliderControllerBgHoverColor: 'rgba(239, 73, 60, 0.8)'
		},
		available: {
			bgColorStart: '#0C0C0C',
			bgColorEnd: 'rgba(29, 29, 29, 0.869954)',
			border: colors.primary,
			shadow: 'rgba(0, 0, 0, 0.25)'
		},
		unavailable: {
			bgColorStart: '#B07F27',
			bgColorEnd: 'rgba(230, 207, 93, 0.869954)'
		},
		item: {
			backgroundColor: '#1d1d1d',
			color: colors.white,
			border: '1px solid #E6CF5D',
			shadow: 'drop-shadow(0px 0px 8px #bf9630)',
			metadata: {
				divider: '#bf9630'
			},
			hoverGradient:
				'linear-gradient(0deg, #1d1d1d 5%, rgba(0, 0, 0, 0) 50%,rgba(0, 0, 0, 0) 100%)',
			channelBackgroundColor:
				'linear-gradient(132.42deg, #1E1E24 7.11%, #2C2C31 94.58%)'
		}
	},
	packets: {
		bg: `linear-gradient(
			123.47deg,
			#202020 0.4%,
			rgba(32, 32, 32, 0.8) 100%
		)`,
		border: 'rgba(243, 243, 243, 0.5)',
		roundSize: 1,
		subtitle: 'rgba(243, 243, 243, 0.8)',
		couponActivation: {
			roundSize: 1,
			border: `linear-gradient(110.41deg, #B07F27 26.44%, #E6CF5D 48.7%, #B07F27 71.91%)`,
			text: '#E2E2E2',
			buttonText: '#fff'
		}
	},
	payment: {
		modal: {
			border: `linear-gradient(
				110.42deg,
				#b07f27 26.61%,
				#e6cf5d 48.71%,
				#b07f27 71.75%
			)`,
			bg: `linear-gradient(97.99deg, #1C1C1C 0%, #333333 100%)`,
			headline: {
				border: '#e5e2e124',
				available: 'rgb(181, 187, 196)'
			}
		},
		bgColor: 'rgba(0, 0, 0, 0.53)',
		gradientBox: {
			color1: colors.primaryLight,
			color2: colors.primaryDark
		},
		gradientRadial: {
			color1: '#3a0d0a',
			color2: colors.black
		},
		paymentOptions: {
			gradientBox: {
				color1: '#f3aea8',
				color2: '#b55d57',
				color3: colors.primaryDark
			},
			paragraph: {
				color: colors.white
			},
			box: {
				bg:
					'linear-gradient(118.88deg, #1B1B1B 1.31%, rgba(32, 32, 32, 0) 99.96%)',
				border: `linear-gradient(
					110.42deg,
					#b07f27 26.61%,
					#e6cf5d 48.71%,
					#b07f27 71.75%
				)`,
				roundSize: 1,
				paymentLogo: {
					bg: '#4b4b4b',
					roundSize: 0.4
				}
			},
			name: {
				color: colors.black,
				fontWeight: fonts.font.semiMedium
			},
			info: {
				color: colors.grayLight,
				fontWeight: fonts.font.normal
			},
			price: {
				color: '#B07F27',
				fontWeight: fonts.font.semiMedium,
				opacity: '0.7'
			},
			paymentField: {
				labelColor: '#e0d7cb',
				color: colors.white,
				placeholderColor: '#e0d7cb',
				borderColor: '#a2544f',
				errorBorderColor: colors.red
			},
			button: {
				bg: '#F0463C',
				bgHover: '#de1c11',
				color: colors.white,
				shadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
				roundSize: 1
			},
			additionalDescription: 'rgba(243, 243, 243, 0.8)'
		},
		voucher: {
			wrapper: {
				borderColor: '#e5e2e124'
			},
			text: {
				color: colors.white
			},
			input: {
				color: colors.white,
				borderColor: 'rgba(105, 114, 135, 0.29)'
			}
		},
		emailNotConfirmed: {
			headingColor: colors.white,
			paragraph: {
				color: colors.white,
				fontWeight: fonts.font.light
			}
		}
	},
	landingPage: {
		registrationButton: {
			color: colors.white,
			bgContainerColor: '#d4594f',
			borderColor: '#313131',
			bgDefault: {
				color1: '#d4594f',
				color2: colors.primaryDark
			},
			bgHover: {
				color1: colors.primaryDark,
				color2: '#d4594f'
			}
		},
		devices: {
			itemColor: colors.primary
		}
	},
	staticContent: {
		heading: colors.white,
		color: colors.grayMediumDarkTheme,
		anchor: colors.primary,
		border: colors.primary
	},
	borderGradient:
		'linear-gradient(90.41deg, #B07F27 0%, #E6CF5D 43.53%, #BF9536 99.5%)',
	listCircleGradient:
		'linear-gradient(122deg, #a87c2f 13.71%, #ab7f32 23.26%, #b58c3b 34.31%, #c6a34a 46.07%, #d0af53 50.77%, #ab7f32 86.27%)',
	notFoundPage: {
		titleColor: '#F3F3F3',
		descriptionColor: 'rgba(243, 243, 243, 0.8)',
		descriptionSecondColor: '#F3F3F3',
		buttonColor: 'rgba(243, 243, 243, 0.8)',
		bgColor: 'rgba(32, 32, 32, 0.8)',
		borderColor: '#b07f27',
		grid: {
			borderColor: '#bf9630'
		}
	},
	common_modal: {
		bg: 'rgba(0, 0, 0, 0.3)',
		title: colors.white,
		description: colors.white,
		content: {
			bg: '#202020',
			bgError: `linear-gradient(130.14deg, #0F0F11 0%, #2C2C31 100%)`,
			border: `linear-gradient(156.32deg, #B07F27 0%, #E6C95D 51.04%, #B07F27 100%)`,
			roundSize: 1
		}
	},
	section_channels_details: {
		bg: `linear-gradient(114.77deg, #0c0c0c 6.85%, #1d1d1d 94.32%)`,
		channel: {
			roundSize: 1.2,
			bg: `linear-gradient(132.42deg, #1e1e24 7.11%, #2c2c31 94.58%)`,
			shadow: '0px 3px 10px rgba(0, 0, 0, 0.45)',
			borderHover: `linear-gradient(180deg, #B07F27 0%, rgba(230, 207, 93, 0.869954) 100%)`,
			dropShadowHover: `drop-shadow(0px 0px 5px #BF9630) drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.7))`
		}
	}
};
