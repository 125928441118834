import i18n from 'i18next';

// Import variables
import {
	ARABIC_SLUGS,
	ENGLISH_SLUGS,
	LANGUAGES_TYPES
} from 'helpers/variables';
import { projectlanguages } from 'helpers/languages';

const { AR } = LANGUAGES_TYPES;

const slugsTranslations = {
	ar: {
		...ARABIC_SLUGS
	},
	en: {
		...ENGLISH_SLUGS
	}
};

const getSlugTranslation = (slug, language) => {
	const { ar, en } = slugsTranslations;
	if (language === AR)
		switch (slug) {
			case ar.MOVIES:
				return en.MOVIES;
			case ar.MOVIE:
				return en.MOVIE;
			case ar.SERIES:
				return en.SERIES;
			case ar.CHANNELS:
				return en.CHANNELS;
			case ar.LIVE:
				return en.LIVE;
			default:
				return slug;
		}
	else {
		switch (slug) {
			case en.MOVIES:
				return ar.MOVIES;
			case en.MOVIE:
				return ar.MOVIE;
			case en.SERIES:
				return ar.SERIES;
			case en.CHANNELS:
				return ar.CHANNELS;
			case en.LIVE:
				return ar.LIVE;
			default:
				return slug;
		}
	}
};

export const getNewPath = ({ pathname, newLng }) => {
	const { language } = i18n;
	const isArLng = language === AR;

	const slugs = isArLng ? ARABIC_SLUGS : ENGLISH_SLUGS;
	const availableSlugs = Object.values(slugs);
	const slug = availableSlugs.find((slug) => pathname.includes(`/${slug}`));
	const translatedSlug = getSlugTranslation(slug, language);

	const [, slugLng] = pathname.split('/');

	const isLangSlugAvailable = projectlanguages.includes(slugLng);

	const coreLngPath = isArLng ? newLng : AR;

	const langSlugPath = isLangSlugAvailable ? `/${language}` : '';

	let windowPathname = '';

	if (isLangSlugAvailable) {
		const newPathname = pathname.replace(langSlugPath, `/${coreLngPath}`);
		windowPathname = newPathname;
	} else {
		const path = `/${coreLngPath}/${pathname}`;
		const newPathname = path.replace('//', '/');
		windowPathname = newPathname;
	}

	if (!!translatedSlug) {
		const newPathname = windowPathname.replace(slug, translatedSlug);
		windowPathname = newPathname;
	}

	return windowPathname;
};
