import React from 'react';

// Import assets
import { ReactComponent as IconVolumeInactiveTVSVG } from 'assets/icons/ic_volume_inactive.svg';
import { ReactComponent as IconVolumeActiveTVSVG } from 'assets/icons/ic_volume_active.svg';

// Import styles
import { ButtonIcon } from '../styles';
import { InnerWrapper, VolumeRangeInput, Wrapper } from './styles';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

export const VolumeController = () => {
	const { volume, setVolume, mute, isMuted } = usePlayerTvContext();

	const handleChangeVolume = (e) => {
		e.persist();
		const value = e.target.value;
		setVolume(value);
	};

	return (
		<Wrapper>
			<InnerWrapper>
				<VolumeRangeInput
					type="range"
					value={volume}
					onChange={handleChangeVolume}
					min="0"
					max="100"
					step="1"
				/>
			</InnerWrapper>

			<ButtonIcon onClick={mute}>
				{isMuted ? <IconVolumeInactiveTVSVG /> : <IconVolumeActiveTVSVG />}
			</ButtonIcon>
		</Wrapper>
	);
};
