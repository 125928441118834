import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SidebarNavStyled, Item, ItemNav, List } from './styles';

// Import helpers
import { createNavList } from 'helpers';

// Import utilities
import ToggleRPC from 'components/utilities/rpc/ToggleRPC';

// Import components
import SidebarSearch from './sidebar_search/SidebarSearch';

const ALL = 'all';

const SidebarNav = ({ categories, category, setCategory, isFullScreen }) => {
	const [navCategories, setNavCategories] = useState([]);

	useEffect(() => {
		const navListCategories = createNavList(categories, ALL);

		setNavCategories(navListCategories);
	}, [categories]);

	const renderItemNav = ({ slug, id, name }) => (
		<Item key={id}>
			<ItemNav
				onClick={handleChangeCategory(slug)}
				isActive={slug === category}
			>
				{name}
			</ItemNav>
		</Item>
	);

	const handleChangeCategory = (slug) => () => setCategory(slug);

	return (
		<SidebarNavStyled>
			<ToggleRPC>
				{({ toggle }) => (
					<>
						<SidebarSearch isOpen openSearchBox={toggle} />
						<List isFullScreen={isFullScreen} isVisible={false}>
							{navCategories.map(renderItemNav)}
						</List>
					</>
				)}
			</ToggleRPC>
		</SidebarNavStyled>
	);
};

SidebarNav.propTypes = {
	categories: PropTypes.array.isRequired,
	category: PropTypes.string,
	setCategory: PropTypes.func.isRequired
};

const mapStateToProps = ({ category: { data: categories } }) => ({
	categories
});

export default connect(mapStateToProps)(SidebarNav);
