import { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { playerOnCancel } from 'store/actions';
import { PLAYER_SELECTORS } from 'helpers/variables';
import { fetchMovieResources } from './helpers';

// Import utilities
import { useAuth } from 'components/utilities';

const playerSelector = PLAYER_SELECTORS.VOD;

export function useLogic({
	uuid: id,
	type = 'vod',
	startCarousel,
	stopStartCarousel,
	videoAssetId
}) {
	const { isReady, isLoaded } = useSelector((state) => state[playerSelector]);
	const { error, errorMessage } = useSelector((state) => state.movieDetails);
	const dispatch = useDispatch();
	const { isAuth } = useAuth();
	const { getState } = useStore();

	useEffect(() => {
		// Stop carousel
		stopStartCarousel();
		// Fetch movie player resources
		fetchMovieResources({ id, type, videoID: videoAssetId, isAuth })(
			dispatch,
			getState
		);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		return () => {
			// Start carousel
			startCarousel();
			// Clear player
			playerOnCancel(playerSelector)(dispatch);
		};
		// eslint-disable-next-line
	}, []);

	return {
		isReady,
		isLoaded,
		error,
		errorMessage,
		type: playerSelector
	};
}
