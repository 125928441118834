// Import translations
import i18next from 'i18next';

export const createNavList = (categories, slugAll = 'all') => [
	{
		slug: slugAll,
		name: `${i18next.t('common_general_all')}`,
		id: 'all'
	},
	{
		slug: 'ulubione',
		name: `${i18next.t('common_header_nav_favourites')}`,
		id: 'favourite'
	},
	...categories
];

// function check if item should be active
// because index route '/' is always active
export const checkNavItemActivity = (location, path) => (data) => {
	const url = data?.url;

	const isMainItem = url?.length <= 3;

	const { pathname } = location;

	const isMainPage = pathname.length <= 3;

	return isMainItem ? isMainPage : pathname.includes(path);
};
