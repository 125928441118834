import { useContext } from 'react';

// Import context
import { PlayerSidebarContext } from './PlayerSidebarProvider';

export const usePlayerSidebarContext = () => {
	const context = useContext(PlayerSidebarContext);

	if (!context) {
		throw new Error(
			'usePlayerSidebarContext must be used within a PlayerSidebarProvider'
		);
	}

	return context;
};
