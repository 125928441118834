import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Menu, Dropdown as AntdDropdown } from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// Import icons
import { ReactComponent as IconLeft } from 'assets/icons/ic_arrow_l.svg';

// Import styles
import { customResponsive, transitionMain, rtl } from 'components/styles';

// Import components
import { Button } from 'components/elements';

// Import styles
import { Nav, ItemStyled } from './styles';

// import helpers
import {
	getTypeTranslation,
	getSearchCategory,
	getAppName,
	checkNavItemActivity
} from 'helpers';
import {
	APP_SUB_PATHS_KEY,
	getNavigationSubPaths
} from 'helpers/navigationSubPaths';

// Import variables
import { ROUTES_CORE_PATHS } from 'helpers';

const { PAYMENTS, HOME, FAVOURITES, SEARCH } = ROUTES_CORE_PATHS;
const { HOME: TV_HOME, CHANNELS } = APP_SUB_PATHS_KEY;

const NavDropdown = ({
	links,
	path = '',
	location: { pathname },
	categories,
	isMainNav = false,
	t
}) => {
	const location = useLocation();
	// Get main path
	const paths = pathname.split('/');
	const corePath = getTypeTranslation(paths[1]);
	const mainPath = paths[2];

	const [slug, setSlug] = React.useState('');

	// Get slug name
	const mainSlug = paths.pop();

	const matchSlug = React.useCallback(
		(slug) => {
			// Convert categories to object with name values
			const categorieConverted = categories.reduce((prev, next) => {
				prev[next.slug] = next.name;
				return prev;
			}, {});
			// If there is a slug return name, else return default text
			return categorieConverted[slug] || t('common_general_all');
		},
		/*eslint-disable-next-line  */
		[categories]
	);

	React.useEffect(() => {
		// Set path slug with matched name
		setSlug(matchSlug(mainSlug));
	}, [mainSlug, matchSlug]);

	// Create link path
	const handleCreatePath = (path, link) =>
		`${path || `${link.path}`}${link.slug && `/${getLinkSlug(link.slug)}`}`;

	// check if user is on home/channels/epg page
	const isChannelsDropdown =
		path === `/tv/${getNavigationSubPaths(CHANNELS)}` ||
		path === '/tv/epg' ||
		path === '/tv' ||
		path === `/tv/${getNavigationSubPaths(TV_HOME)}`;

	const isSearchPage = corePath === 'search';
	const isMainPage = pathname === '/';

	// Get translated slug
	const getLinkSlug = (slug) => {
		if (isChannelsDropdown) {
			return slug;
		}

		return isSearchPage ? getSearchCategory(slug) : getTypeTranslation(slug);
	};

	const appName = getAppName();

	// Create nav item text
	const handleNavItemText = ({ name, translationSection }) => {
		const navItem = name === 'home' ? `home_${appName}` : name;
		return isMainNav ? t(`common_${translationSection}_${navItem}`) : navItem;
	};

	// Create button text
	const handleButtonText = () => {
		const navItem = isSearchPage ? SEARCH : mainPath;
		const navItemTranslated = getTypeTranslation(navItem);
		const addAppName = navItem === HOME || navItem === PAYMENTS;

		const translationSlug = addAppName
			? `${navItemTranslated}_${appName}`
			: navItemTranslated;

		if (isMainNav) {
			if (translationSlug) {
				return t(`common_${links[0].translationSection}_${translationSlug}`);
			}

			return isMainPage ? t('common_header_nav_main') : 'Widekhaliji';
		} else if (isSearchPage) {
			const paths = pathname.split('/');
			return t(`search_dropdown_${getSearchCategory(paths[3])}`);
		} else if (pathname.includes(FAVOURITES)) {
			return t(`common_header_nav_favourites`);
		}

		return slug || t('common_general_all');
	};

	const dropdownItems = (
		<Menu>
			{links.map((link) => (
				<ItemStyled key={link.id}>
					<Nav
						exact={!isMainNav}
						to={handleCreatePath(path, link)}
						activeClassName="active-drop-nav"
						isActive={checkNavItemActivity(location)}
					>
						{/* Create nav item text */}
						{handleNavItemText(link)}
					</Nav>
				</ItemStyled>
			))}
		</Menu>
	);
	return (
		<NavDropdownStyled>
			{!isMainNav && <Text>{t('common_category')}:</Text>}
			<AntdDropdown overlay={dropdownItems} trigger={['click']}>
				<ButtonStyled width="20rem" modifiers={['dropdown']}>
					{/* Create button text */}
					{handleButtonText()}
					<IconDown />
				</ButtonStyled>
			</AntdDropdown>
		</NavDropdownStyled>
	);
};

const NavDropdownStyled = styled.div`
	display: none;
	${customResponsive(
		1200,
		css`
			display: flex;
			align-items: center;
			padding: 0.8rem;
		`
	)}
`;

const Text = styled.span`
	color: ${({ theme }) => theme.grayMedium};
	font-weight: ${({ theme }) => theme.font.normal};
	font-size: 1.4rem;
	${rtl`
		margin-right: 1.5rem;
	`}
`;

const IconDown = styled(IconLeft)`
	width: 0.8rem;
	height: 1rem;
	transform: rotate(-90deg);
	polygon {
		${transitionMain}
	}
`;

const ButtonStyled = styled(Button)`
	&:hover,
	&.ant-dropdown-open {
		color: ${({ theme: { dropdown } }) => dropdown.button.colorHover};
		${IconDown} {
			polygon {
				fill: ${({ theme }) => theme.white};
			}
		}
	}
`;

NavDropdown.propTypes = {
	links: PropTypes.array.isRequired,
	path: PropTypes.string,
	categories: PropTypes.array.isRequired,
	isMainNav: PropTypes.bool,
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

const mapStateToProps = ({ category: { data } }) => ({ categories: data });

export default compose(
	connect(mapStateToProps),
	withRouter,
	withTranslation()
)(React.memo(NavDropdown));
