import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTvProgramsLive } from 'store/actions';

// Import helpers
import { UPDATE_LIVE_PROGRAM_INTERVAL } from 'helpers/variables';

const useUpdateCurrentProgram = () => {
	const isMounted = React.useRef(false);

	const dispatch = useDispatch();

	const { lives } = useSelector((state) => state.channels.data);
	const { channelID } = useSelector((state) => state.selected_program);

	// Find channel
	const channel = lives.find(({ uuid }) => uuid === channelID) || lives[0];

	const epgProgrammes = channel?.epgProgrammes;
	const currentLiveIndex = channel?.currentLiveIndex;

	// Check if program exists
	const isProgramAvaiable = !!epgProgrammes?.[currentLiveIndex];

	React.useEffect(() => {
		isMounted.current = true;
		const intervalTime = UPDATE_LIVE_PROGRAM_INTERVAL;
		let intervalID = null;

		const isCurrentMounted = isMounted.current;

		// Calculate initial progress
		isCurrentMounted && isProgramAvaiable && calculateProgress();

		const isAbleToCalculate = isCurrentMounted && isProgramAvaiable;

		if (isAbleToCalculate) {
			// Calculate update progress
			intervalID = setInterval(calculateProgress, intervalTime);
		}

		return () => {
			isMounted.current = false;
			clearInterval(intervalID);
		};
		// eslint-disable-next-line
	}, [currentLiveIndex]);

	const calculateProgress = () => {
		const { till } = epgProgrammes[currentLiveIndex];
		const dateNow = new Date();
		const tillDate = new Date(till.replace(/-/g, '/'));

		// Calculate diff and diff now
		const calculateDiff = (date) =>
			(tillDate.getTime() - date.getTime()) / 1000 / 60;

		const diffNow = calculateDiff(dateNow);

		if (diffNow <= 0 && isMounted.current) {
			// Dispatch an action
			dispatch(setTvProgramsLive(channelID));
		}
	};
};

export default useUpdateCurrentProgram;
