import styled, { css } from 'styled-components';
import { Button } from 'components/elements/index';
import { borderGradient, transitionMain } from 'components/styles';

// Import utilities
import { flex } from 'components/styles';

export const Content = styled.div`
	position: absolute;
	bottom: 55px;
	left: 50%;
	transform: translateX(-50%);
	padding: 1rem;
	background-color: ${({ theme }) => theme.player.settings.TVBackgroundColor};
	opacity: 0;
	visibility: hidden;
	border-radius: 0.7rem;
	line-height: 1.9;

	${({ theme }) => css`
		${borderGradient({
			borderSize: 2,
			borderBg: theme.player.settings.borderColor
		})}
	`}

	${({ isVisible }) =>
		isVisible &&
		css`
			opacity: 1;
			visibility: visible;
		`}
`;

export const Profiles = styled.ul`
	list-style: none;
	display: flex;
`;

export const Profile = styled.li`
	margin-bottom: 0.8rem;
	color: ${({ theme }) => theme.player.settings.profile.color};
	transition: all 0.3 ease-in-out;
	cursor: pointer;
	padding: 0 5.5rem;

	&:hover {
		color: ${({ theme }) => theme.player.settings.profile.hover};
	}

	${({ profile, selected }) =>
		profile === selected &&
		css`
			color: ${({ theme }) => theme.player.settings.profile.selected};
		`}
`;

export const ProfilesBox = styled.div`
	text-align: center;
	z-index: 0;

	font-size: 2rem;
	font-weight: ${({ theme }) => theme.font.normal};
`;

export const ProfileName = styled.p`
	color: ${({ theme }) => theme.player.settings.profile.titleColor};
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid
		${({ theme }) => theme.player.settings.profile.borderTopColor};
`;

export const Wrapper = styled.div`
	position: absolute;
	right: 0;
	left: 0;
	z-index: 9999;
	width: 100%;
	height: 14rem;
	bottom: -0.1rem;
	${flex({ jc: 'flex-end' })}
	padding: ${({ isFullScreen }) => (isFullScreen ? '0 4rem' : '0 2rem')};
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	background: ${({ theme: { playerTv } }) => playerTv.controllers.bg};
	transition: all 0.3s ease-in-out;
`;

export const StyledButton = styled(Button)`
	margin: 0 1rem;
`;

export const LiveIndicatorWrapper = styled.div`
	${flex({ jc: 'flex-start' })}
`;

export const LiveDot = styled.div`
	height: 1.6rem;
	width: 1.6rem;
	background-color: ${({ theme, isActive }) =>
		isActive
			? theme.player.liveIndicator.dot.active
			: theme.player.liveIndicator.dot.inactive};
	border-radius: 50%;
	display: inline-block;
`;

export const LiveIndicatorText = styled.span`
	font-size: 1.9rem;
	font-weight: ${({ theme }) => theme.font.medium};
	text-transform: uppercase;
	color: ${({ theme }) => theme.player.liveIndicator.color};
	padding-left: 1rem;
`;

export const SettingsWrapper = styled.div`
	${flex()}
	gap: 2.8rem;
`;

export const ButtonIcon = styled.button`
	cursor: pointer;
	width: 4rem;
	height: 4rem;
	${flex()};
	background: none;
	border: none;
	color: ${({ theme: { playerTv } }) => playerTv.button.color};
	${transitionMain()};

	&:hover {
		color: ${({ theme: { playerTv } }) => playerTv.button.colorHover};
	}
`;
