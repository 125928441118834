import { NO_PROGRAM } from 'helpers/variables';

/**
 * Normalizes a string for comparisons.
 * Removes diacritics and converts to lowercase.
 * @param {string} str - String to normalize.
 * @returns {string} - Normalized string.
 */
const normalizeString = (str) =>
	str
		? str
				.trim()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase()
		: '';

/**
 * Filters channels based on query, category, and locale.
 * @param {Object} params - Filter parameters.
 * @param {Array} params.lives - List of channels.
 * @param {string} params.query - Search query.
 * @param {string} params.category - Filter category.
 * @param {string} params.locale - Locale.
 * @returns {Array} - Filtered channels.
 */
export const getFilteredChannels = ({ lives, query, category, locale }) => {
	// Normalize and prepare the query
	const normalizedQuery = normalizeString(query);

	const channels = lives.reduce((acc, live) => {
		const { title, currentLiveIndex, epgProgrammes, translations } = live;

		// Get the current program
		const currentProgram = epgProgrammes[currentLiveIndex];

		// Get the translated channel title, if available
		const translatedTitle = translations?.[locale]?.title || title;
		const formattedLiveTitle = normalizeString(translatedTitle);

		// Function to check if title contains the query
		const formatTitle = (str) => str.includes(normalizedQuery);

		// Get the translated title of the current program, if available
		const currentProgramTitle =
			currentProgram?.translations?.[locale]?.title ||
			currentProgram?.title ||
			'';

		const formattedCurrentProgramTitle = normalizeString(currentProgramTitle);

		// Check if the current program contains the query
		const isCurrentProgram =
			currentProgram?.uuid !== NO_PROGRAM &&
			formatTitle(formattedCurrentProgramTitle);

		// Add the channel to the results if the channel title or current program contains the query
		if (formatTitle(formattedLiveTitle) || isCurrentProgram) {
			acc.push(live);
		}

		return acc;
	}, []);

	// Filter channels by category
	const channelsFiltered = channels.filter(
		({ genre }) => genre?.[0]?.slug === category
	);

	// Return all channels if the category is 'all', otherwise return the filtered channels
	return category === 'all' ? channels : channelsFiltered;
};
