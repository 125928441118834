import styled from 'styled-components';

// Import utilities
import { iconSearch, flex, rtl } from 'components/styles';

// Import icons
import { ReactComponent as SearchIcon } from 'assets/icons/ic_search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ic_close.svg';

// Import component
import { Button } from 'components/elements';

export const SearchIconStyledLeft = styled(SearchIcon)`
	${iconSearch(2.4, 2.4)}
	position: absolute;

	${rtl`
			left: 1rem;
		`}
`;

export const SearchBox = styled.div`
	${flex({ jc: 'space-between' })}
	position: relative;
`;

export const ButtonStyled = styled(Button)`
	padding: 0rem 0.6rem;
	background-color: transparent;
`;

export const ButtonClose = styled(ButtonStyled)`
	top: 0.5rem;
	display: block;
	width: 2.4rem;
	height: 3rem;
	position: absolute;

	${rtl`
			right: 1.6rem;
		`}
`;

export const CloseIconStyled = styled(CloseIcon)`
	${iconSearch(1.5, 1.5)}
`;

export const InputSearch = styled.input`
	flex: 1;
	background: ${({ theme }) => theme.playerTv.sidebar.search.background};
	border: 0;
	border-radius: 0.6rem;
	padding: 0.7rem 1rem;

	${rtl`
			padding-left:4.8rem;
		`}
	color: ${({ theme }) => theme.playerTv.sidebar.search.color};
	font-size: 1.4rem;

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: ${({ theme }) => theme.playerTv.sidebar.search.placeholder};
		font-size: 1.4rem;
	}
`;
