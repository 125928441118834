require('dotenv').config();

const BASE_URL = process.env.REACT_APP_API_URL;

export const hostname = process.env.REACT_APP_WEBSITE_URL;

export const destinationFilePath = './public/sitemap.xml';

export const ARABIC_SLUGS = {
	MOVIES: 'أفلام',
	MOVIE: 'فيلم',
	SERIES: 'مسلسلات',
	CHANNELS: 'القنوات'
};

export const ENGLISH_SLUGS = {
	MOVIES: 'movies',
	SERIES: 'series',
	PACKET: 'packet',
	CHANNELS: 'channels'
};

export const PRODUCT_TYPES = {
	CHANNEL: 'channel',
	CHANNELS: 'channels',
	VOD: 'vod',
	SERIES: 'series',
	SEASON: 'season',
	EPISODE: 'episode',
	PROVISION: 'provision',
	COLLECTION: 'collection',
	MAIN: 'main'
};

export const LIST_PRODUCT_URL = `${BASE_URL}/sitemap/listProducts?limit=10000`;
export const LIST_SECTIONS_URL = `${BASE_URL}/sitemap/listSections`;
export const LIST_PACKETS_URL = `${BASE_URL}/sitemap/listPackets`;
export const LIST_PROGRAMS_URL = `${BASE_URL}/sitemap/listPrograms`;

export const DATE_FORMAT = 'YYYY-MM-DD';

export const PROGRAM = 'program';
