import React from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { convertTime } from 'helpers';

// Import components
import { Info, TimeBox, Time, ParagraphStyled, Wrapper } from './styles';

// Import hooks
import useProductRatingCheck from 'components/elements/molecules/parental_control/parental_control_utils/useProductRatingCheck';

const SidebarInfo = ({ since, till, title, isUpcoming, pcRating }) => {
	// check program rating and block content accordingly
	const { isContentBlocked, placeholderRatingText } = useProductRatingCheck(
		pcRating,
		since
	);

	return (
		<Info isUpcoming={isUpcoming}>
			{isContentBlocked ? (
				<ParagraphStyled>{placeholderRatingText}</ParagraphStyled>
			) : (
				<Wrapper>
					<TimeBox>
						<Time>{convertTime(since, till)}</Time>
					</TimeBox>
					{title && <ParagraphStyled>{title}</ParagraphStyled>}
				</Wrapper>
			)}
		</Info>
	);
};

SidebarInfo.propTypes = {
	since: PropTypes.string.isRequired,
	till: PropTypes.string.isRequired,
	title: PropTypes.string,
	isUpcoming: PropTypes.bool,
	pcRating: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
};

export default SidebarInfo;
