import { createGlobalStyle, css } from 'styled-components';
import { transitionMain, rtl, borderGradient } from 'components/styles';

const GlobalStyle = createGlobalStyle`

  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,body,#root {
       height: 100%;
  }

  html {
    font-size: 62.5%;
    background-color: ${({ theme }) => theme.general.html}
  }

  body {
    font-size: 1.6rem;
    font-family: "Noto Sans", sans-serif;
    ${({ isRtl }) =>
			isRtl &&
			css`
			font-family: "Noto Kufi Arabic", sans-serif;
			}
		`}
    background-color: ${({ theme }) => theme.body.bg};

    @media (min-width: 900px) {
      overflow-x: hidden;
    }
  }

  a:hover {
    color: ${({ theme: { general } }) => general.linkHover}
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  ul, p {
    margin-bottom: 0;
  }

  svg {
        pointer-events: none;
  }

  

  /* Dropdown */
  .ant-dropdown-menu {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.bg}
  }
  .ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider {
    background-color: ${({ theme }) => theme.line};
  }

/* Drawer */
  ${({ isRtl }) =>
		isRtl &&
		css`
			.ant-drawer {
				transform: scale(-1, 1) !important;
				right: auto !important;
				left: 0;
			}
		`}

  /* Notification menu on Popover and Program detail page */
  .ant-menu {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.bg}
  }
  .ant-menu-vertical {
    border-right: none;
  }
  .ant-menu-item {
    color: ${({ theme: { dropdown } }) => dropdown.menu.itemColor};

    &.ant-menu-item-active {
      background-color: ${({ theme: { dropdown } }) =>
				dropdown.menu.itemHoverBg};
      color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;

    &:hover {
      background-color: ${({ theme: { dropdown } }) =>
				dropdown.menu.itemHoverBg};
    }
  }


  /* Popover */
  .ant-popover-inner-content {
    padding: 0 !important;
  }
  .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
		border-bottom-color: ${({ theme: { popover } }) => popover.bg};
		border-left-color: ${({ theme: { popover } }) => popover.bg};
  }
  .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
    border-top-color: ${({ theme: { popover } }) => popover.bg};
    border-right-color: ${({ theme: { popover } }) => popover.bg};
  }


  /* Select */
  .ant-select {
    height: 4.2rem;
    color: ${({ theme: { dropdown } }) => dropdown.button.color};
    font-size: 1.3rem;
    font-weight: ${({ theme }) => theme.font.medium};
    user-select: none;
    background: ${({ theme: { dropdown } }) => dropdown.button.bg};

    .ant-select-selection {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 5px;
    }


    .ant-select-selection--single {
      height: 100%;
    }

    .ant-select-selection__rendered {
      line-height: 4rem;
    }

    .ant-select-arrow {
      color: ${({ theme: { dropdown } }) => dropdown.button.color};
      ${transitionMain}
    }

    &.ant-select-open .ant-select-selection {
      border-color: ${({ theme }) => theme.line};
      box-shadow: none;
    }
    &.ant-select-open .ant-select-arrow-icon svg {
      width: 1.1rem;
      transform: none;
    }

    &.ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      border-color: ${({ theme }) => theme.line};
      box-shadow: none;
    }

    &:hover,
    &.ant-select-open {
      color: ${({ theme: { dropdown } }) => dropdown.button.colorHover};

      .ant-select-arrow {
        color: ${({ theme: { dropdown } }) => dropdown.button.colorHover};
      }
    }
  }

  .ant-select-dropdown {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.bg}
  }

  .ant-select-dropdown-menu-item {
    color: ${({ theme: { dropdown } }) => dropdown.menu.itemColor};
  }

  .ant-select-dropdown-menu-item-active{
    background-color: transparent !important;
    color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
  }

  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverBg};
		color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};

     ${({ isRtl }) =>
				isRtl &&
				css`
					background: ${({ theme: { dropdown } }) =>
						dropdown.menu.itemHoverBgRtl};
				`};
      
      &>div span:first-child {
        color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
      }

      &>div span:last-child {
        color: ${({ theme: { dropdown } }) => dropdown.menu.itemColor};
      }
  }

  .ant-select-dropdown-menu-item-selected:not(.ant-select-dropdown-menu-item-disabled) {
			color: ${({ theme: { dropdown } }) => dropdown.menu.itemSelected};
      background-color: transparent;

      &>div span {
        color: ${({ theme: { dropdown } }) => dropdown.menu.itemSelected};
      }
  }

  .ant-empty-image {
    display: none !important;
  }

  .ant-empty-description {
    color:${({ theme: { white } }) => white}
  }

  /* Calendar */
  .ant-calendar-input-wrap {
		display: none;
	}
  .ant-calendar-prev-year-btn,
  .ant-calendar-next-year-btn {
    display:none !important;
  }

  .ant-calendar-year-select,
  .ant-calendar-month-select {
    pointer-events: none;
  }

  .ant-calendar-ym-select {
		display: flex;
		justify-content: center;
	}

	.ant-calendar-year-select {
		order: 2;
	}

	.ant-calendar-month-select {
		order: 1;
	}

.ant-calendar {
		background-color: ${({ theme }) => theme.calendar.bg};
		border: 1px solid ${({ theme }) => theme.modal.bg};
	}

	.ant-calendar-prev-month-btn,
	.ant-calendar-prev-year-btn,
	.ant-calendar-next-month-btn,
	.ant-calendar-next-year-btn,
  .ant-calendar-year-select,
  .ant-calendar-month-select,
  .ant-calendar-column-header,
  .ant-calendar-cell,
  .ant-calendar-last-month-cell {
		color: ${({ theme }) => theme.white} !important;

		&:hover {
			color: ${({ theme }) => theme.white};
		}
	}

  .ant-calendar-header .ant-calendar-prev-month-btn:hover::before, .ant-calendar-header .ant-calendar-prev-month-btn:hover::after,.ant-calendar-header .ant-calendar-next-month-btn:hover::before, .ant-calendar-header .ant-calendar-next-month-btn:hover::after {
    border-color: ${({ theme }) => theme.white} !important;
  }

  .ant-calendar-header,.ant-calendar-footer {
    border: 1px solid transparent !important;
  }

  .ant-calendar-footer{
    position: relative;
    z-index: 9999;
  }

  .ant-calendar-footer {
    padding-bottom: 1rem;
  }

  .ant-calendar-disabled-cell .ant-calendar-date {
			background: ${({ theme }) => theme.buttonDefault.bg};
  }
  
  .ant-calendar-date {
    color: ${({ theme }) => theme.calendar.date} !important;
  }

  .ant-calendar-date:hover {
    background: transparent !important;
  }

  .ant-calendar-cell .ant-calendar-selected-date .ant-calendar-selected-day {
    .ant-calendar-selected-day .ant-calendar-date {

    background: transparent !important;
    }
  }

  .ant-calendar-today .ant-calendar-date,
  .ant-calendar-selected-day .ant-calendar-date {
    background: inherit !important;
    border: none;
  }

  .ant-calendar-selected-date {
    .ant-calendar-date {
      border: 1px solid ${({ theme }) => theme.calendar.selectedDay} !important;
      font-weight: ${({ theme }) => theme.font.medium} !important;
    }

    &.ant-calendar-today .ant-calendar-date {
      background: inherit !important;
    }
  }

  .ant-calendar-selected-day.ant-calendar-disabled-cell {
    .ant-calendar-date {
      background: ${({ theme }) => theme.buttonDefault.bg} !important;
      font-weight: inherit !important;
      border: 1px solid transparent !important;

      &:hover {
          background: transparent !important;
      }

      &::before {
          display: none !important;
      }
    }
  }

  .ant-calendar-today-btn {
    position: relative;
    width: 100%;
    font-weight: ${({ theme }) => theme.font.medium};
    background-color: ${({ theme: { buttonPrimary } }) => buttonPrimary.bg};
    color: ${({ theme: { buttonPrimary } }) => buttonPrimary.color};
    border-radius: 0.4rem;

    ${({ theme }) =>
			css`
				${borderGradient({
					borderSize: 2,
					borderBg: theme.buttonPrimary.border
				})}
			`};

      
    &:hover,
    &:focus {
			background-color:${({ theme: { buttonPrimary } }) => buttonPrimary.bgHover};
    }
  }



  /* Payment pin input style for Safari */
  @media not all and (min-resolution:.001dpcm) {
    @media {
      input.mask {
        font-size: 3.6rem;
        line-height: 1;
        padding-bottom: 0;
      }
    }
  }


//  /* React virtualized styles */

    .ReactVirtualized__Grid, .ReactVirtualized__Grid__innerScrollContainer {
      &:focus {
        outline: none;
      }
    }

    /* Details content tooltip style */
    .productTitle-tooltip {
      .ant-tooltip-inner {
        width: max-content;
        border: 0.5px solid rgba(255, 255, 255, 0.5);
        color: ${({ theme }) => theme.vod.font.whiteGrayLight};
      }
    }

    /* Epg popover arrow style */
    .epg-popover {
      .ant-popover-arrow {
        border-color: ${({ theme }) => theme.popover.bg} !important;
        z-index: -1;
      }
    }



    /* TODO: Adding player styles to global styles of this component */
    .ant-notification:has(.player_tv_notification) {
      width: 43rem;
    }

    .player_tv_notification{
      ${rtl`padding-left: 1.4rem`};

      .ant-notification-notice-message{
        ${({ isRtl }) => isRtl && `padding-right: 4.4rem`};
        width: 100%;
        font-size: 1.4rem;
        font-weight: ${({ theme }) => theme.font.regular};
      }
    }

    .ant-notification-notice-close{
      ${rtl`right: 5%;
      left: auto;`}
    }
    

    /* Recaptcha styles */
    .grecaptcha-badge {
	  	display: none;
	  }

    
`;

export default GlobalStyle;
