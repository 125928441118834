import { useState } from 'react';

// Import utilities
import { useLocalStorage } from 'components/utilities';

// Import variables
import { LOCAL_STORAGE_PLAYER_WATCHLIST_GUIDELINE } from 'helpers/variables';

export const useWatchlistModal = () => {
	const [isModalVisible, setModalVisible] = useState(false);
	const [isModalNextVisitEnabled, setModalNextVisitEnabled] = useState(false);
	const [isModalAvailable, setModalAvailable] = useLocalStorage(
		LOCAL_STORAGE_PLAYER_WATCHLIST_GUIDELINE,
		true
	);

	const toggleModalNextVisit = () => setModalNextVisitEnabled((prev) => !prev);

	const closeModal = () => {
		setModalVisible(false);
		setModalAvailable(!isModalNextVisitEnabled);
	};

	return {
		showModal: () => setModalVisible(true),
		closeModal,
		toggleModalNextVisit,
		isModalVisible,
		isModalAvailable,
		isModalNextVisitEnabled
	};
};
