import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Import assets
import GuidelineImageAr from 'assets/images/watchlist_guideline_ar.svg';
import GuidelineImageEng from 'assets/images/watchlist_guideline_eng.svg';
import { ReactComponent as IconClose } from 'assets/icons/ic_guideline_close.svg';

// Import styles
import { CloseButton, Description, InnerWrapper, Wrapper } from './styles';

// Import components
import { Checkbox } from './Checkbox/Checkbox';

// Import hooks
import { usePlayerSidebarContext } from 'components/context/player/usePlayerSidebarContext';

export const SidebarWatchlistModal = () => {
	const {
		isModalVisible,
		closeModal,
		toggleModalNextVisit,
		isModalAvailable,
		isModalNextVisitEnabled
	} = usePlayerSidebarContext();

	const { isRtl } = useSelector(({ direction }) => direction);

	const { t } = useTranslation();

	if (!isModalAvailable || !isModalVisible) return null;

	return (
		<Wrapper>
			<CloseButton onClick={closeModal}>
				<IconClose />
			</CloseButton>

			<InnerWrapper>
				<img
					src={isRtl ? GuidelineImageAr : GuidelineImageEng}
					alt="guideline"
				/>

				<Description>
					<span>{t('player_tv_watchlist_guideline_question')}</span>
					{t('player_tv_watchlist_guideline_answer')}
				</Description>

				<Checkbox
					onChange={toggleModalNextVisit}
					checked={isModalNextVisitEnabled}
				>
					{t('player_tv_watchlist_guideline_checkbox')}
				</Checkbox>
			</InnerWrapper>
		</Wrapper>
	);
};
