import i18next from 'i18next';

// Import variables
import { ARABIC_SLUGS } from 'helpers/variables';

const { MOVIES, MOVIE, SERIES, CHANNELS } = ARABIC_SLUGS;

const navigationSubPaths = {
	en: {
		login: 'login',
		resetPassword: 'reset-password',
		watch: 'watch',
		movies: 'movies',
		movie: 'movie',
		series: 'series',
		singleSeries: 'series',
		packet: 'packet',
		packets: 'packets',
		page: 'page',
		regulation: 'regulation',
		search: 'search',
		channels: 'channels',
		home: 'home',
		about_us: 'about-us',
		kids: 'kids',
		collection: 'collection',
		vod: 'vod',
		epg: 'epg',
		section: 'section',
		sectionChannelsDetails: 'section_channels',
		all: 'all',
		program: 'program',
		user: 'user',
		payments: 'payments',
		general: 'general',
		devices: 'devices',
		recordings: 'recordings',
		products: 'products',
		favourites: 'favourites',
		programs: 'programs',
		episode: 'episode',
		season: 'season',
		rodo: 'rodo',
		subscriptions: 'subscriptions',
		register: 'register',
		'program-details': 'program-details',
		transaction: 'transaction',
		privacy_policy: 'privacy-policy',
		contact: 'contact',
		support: 'support',
		watchlist: 'watchlist',
		deleting_account: 'deleting-account',
		entry_tickets: 'entry-tickets'
	},
	ar: {
		login: 'login',
		resetPassword: 'reset-password',
		watch: 'watch',
		movies: MOVIES,
		movie: MOVIE,
		series: SERIES,
		singleSeries: 'series',
		packet: 'packet',
		packets: 'packets',
		page: 'page',
		regulation: 'regulation',
		search: 'search',
		channels: CHANNELS,
		home: 'home',
		about_us: 'about-us',
		kids: 'kids',
		collection: 'collection',
		vod: 'vod',
		epg: 'epg',
		section: 'section',
		sectionChannelsDetails: 'section_channels',
		all: 'all',
		program: 'program',
		user: 'user',
		payments: 'payments',
		general: 'general',
		devices: 'devices',
		recordings: 'recordings',
		products: 'products',
		favourites: 'favourites',
		programs: 'programs',
		episode: 'episode',
		season: 'season',
		rodo: 'rodo',
		subscriptions: 'subscriptions',
		register: 'register',
		'program-details': 'program-details',
		transaction: 'transaction',
		privacy_policy: 'privacy-policy',
		contact: 'contact',
		support: 'support',
		watchlist: 'watchlist',
		deleting_account: 'deleting-account',
		entry_tickets: 'entry-tickets'
	},
	pl: {
		login: 'logowanie',
		resetPassword: 'reset-hasla',
		watch: 'ogladaj',
		movies: 'filmy',
		movie: 'film',
		series: 'seriale',
		singleSeries: 'serial',
		packets: 'pakiety',
		packet: 'pakiet',
		page: 'strona',
		regulation: 'regulamin',
		search: 'szukaj',
		channels: 'kanaly',
		home: 'strona-glowna',
		about_us: 'o-nas',
		kids: 'dla-dzieci',
		collection: 'kolekcje',
		main: 'pakiet',
		vod: 'vod',
		epg: 'epg',
		section: 'sekcja',
		sectionChannelsDetails: 'sekcja_kanaly',
		all: 'wszystkie',
		program: 'programy',
		user: 'uzytkownik',
		payments: 'historia-platnosci',
		general: 'profil',
		devices: 'urzadzenia',
		recordings: 'nagrania',
		products: 'aktywne-wypozyczenia',
		favourites: 'ulubione',
		programs: 'programy',
		episode: 'odcinek',
		season: 'sezon',
		rodo: 'rodo',
		subscriptions: 'subskrypcje',
		register: 'rejestracja',
		'program-details': 'szczegoly-programu',
		transaction: 'transakcja',
		privacy_policy: 'polityka-prywatnosci',
		watchlist: 'watchlist',
		deleting_account: 'deleting-account',
		entry_tickets: 'entry-tickets'
	}
};

export const APP_SUB_PATHS_KEY = {
	LOGIN: 'login',
	RESET_PASSWORD: 'resetPassword',
	WATCH: 'watch',
	MOVIES: 'movies',
	MOVIE: 'movie',
	SERIES: 'series',
	SINGLE_SERIES: 'singleSeries',
	PACKET: 'packet',
	PACKETS: 'packets',
	PAGE: 'page',
	REGULATION: 'regulation',
	SEARCH: 'search',
	CHANNELS: 'channels',
	HOME: 'home',
	ABOUT_US: 'about_us',
	KIDS: 'kids',
	COLLECTION: 'collection',
	VOD: 'vod',
	EPG: 'epg',
	SECTION: 'section',
	SECTION_CHANNELS_DETAILS: 'sectionChannelsDetails',
	ALL: 'all',
	PROGRAM: 'program',
	USER: 'user',
	PAYMENTS: 'payments',
	GENERAL: 'general',
	DEVICES: 'devices',
	RECORDINGS: 'recordings',
	PRODUCTS: 'products',
	FAVOURITES: 'favourites',
	PROGRAMS: 'programs',
	EPISODE: 'episode',
	SEASON: 'season',
	RODO: 'rodo',
	SUBSCRIPTIONS: 'subscriptions',
	REGISTER: 'register',
	PROGRAM_DETAILS: 'program-details',
	TRANSACTION: 'transaction',
	PRIVACY_POLICY: 'privacy_policy',
	CONTACT: 'contact',
	SUPPORT: 'support',
	WATCHLIST: 'watchlist',
	DELETING_ACCOUNT: 'deleting_account',
	ENTRY_TICKETS: 'entry_tickets',
	LIVE: 'live'
};

export const getNavigationSubPaths = (key) => {
	const { language } = i18next;
	const paths = navigationSubPaths[language] ?? navigationSubPaths.ar;
	return paths[key];
};
