import React from 'react';

// Import assets
import { ReactComponent as IconFullscreenInactiveTVSVG } from 'assets/icons/ic_fullscreen_inactive.svg';
import { ReactComponent as IconFullscreenActiveTVSVG } from 'assets/icons/ic_fullscreen_active.svg';

// Import styles
import { ButtonIcon } from './styles';

// Import hooks
import { usePlayerTvContext } from 'components/context/player/player_tv';

export const FullscreenController = () => {
	const { isFullScreen, setFullScreen } = usePlayerTvContext();

	return (
		<ButtonIcon onClick={setFullScreen}>
			{isFullScreen ? (
				<IconFullscreenActiveTVSVG />
			) : (
				<IconFullscreenInactiveTVSVG />
			)}
		</ButtonIcon>
	);
};
