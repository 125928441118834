import styled from 'styled-components';

// Import components
import { flex, rtl } from 'components/styles';

export const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 21.4rem;
	padding-top: 4.5rem;
	${rtl`
	padding-left: 4rem;
	direction: ltr;
	`}
	${flex({ jc: 'flex-start', ai: 'flex-start', fd: 'column' })}
	background: ${({ theme: { playerTv } }) => playerTv.heading.bg};
`;

export const Heading = styled.h1`
	font-size: 2.6rem;
	font-weight: ${({ theme: { font } }) => font.semiMedium};
	line-height: 3.1rem;
	color: ${({ theme: { playerTv } }) => playerTv.heading.title};
`;

export const Duration = styled.p`
	font-size: 1.4rem;
	font-weight: ${({ theme: { font } }) => font.regular};
	line-height: 2.5rem;
	color: ${({ theme: { playerTv } }) => playerTv.heading.duration};
`;
