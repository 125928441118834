import styled from 'styled-components';

// Import styles
import { flex, transitionMain, textEllipsis } from 'components/styles';

export const Wrapper = styled.div`
	position: absolute;
	width: 90%;
	left: 0;
	right: 0;
	margin: 0 auto;
	bottom: 12rem;
	z-index: 1;
`;

export const InnerWrapper = styled.div`
	width: max-content;
	padding: 1.5rem;
	margin-left: 3rem;
	${flex()}
	column-gap: 1.5rem;
	transform: translateX(-100%);
	${transitionMain()}
	background-color: ${({ theme }) => theme.player.cast.name.backgroundColor};
	border-radius: ${({ theme }) => theme.player.cast.name.roundSize}rem;
	color: ${({ theme }) => theme.player.cast.name.color};


	&.entering,
	&.exiting {
		transform: translateX(-100%);
		opacity: 0;
	}

	&.entered {
		transform: translateX(0rem);
		opacity: 1;
	}
`;

export const Name = styled.p`
	color: ${({ theme }) => theme.player.cast.name.color};
	font-weight: ${({ theme }) => theme.font.medium};
	font-size: 2rem;
	flex: 1;
	${textEllipsis}
`;
