import React, { useEffect } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MOBILE_DEVICES_WEBSITE_URL } from 'helpers/variables';
import { getAppStoreUrls } from 'components/layout/footer/helpers';
// Import utilities
import LoadableMng from 'components/utilities/lodable_manager/LodableMng';
import useUserAgent from 'components/utilities/hooks/user_agent/useUserAgent';

// Import components
import ProtectedUserLogged from 'components/hoc/ProtectedUserLogged';
import RegulationRedirect from 'components/views/RegulationRedirect';
import PageNotFound from 'components/views/PageNotFound';
import Error404 from 'components/views/PageNotFound/Error404';

// Import helpers
import {
	getNavigationPaths,
	APP_PATHS_KEY
} from 'components/routes/helpers/paths';
import { PAGE_TITLES } from 'helpers/gtm_tracking';

const {
	REGULATION,
	LOGIN,
	PRIVACY_POLICY,
	ABOUT,
	NON_SUBSCRIBER,
	CONTACT,
	SUPPORT,
	ERROR_404
} = APP_PATHS_KEY;

const {
	TERMS_AND_CONDITIONS,
	PRIVACY_POLICY: PRIVACY_POLICY_TITLE,
	ABOUT_US: ABOUT_US_TITLE,
	CONTACT: CONTACT_TITLE,
	SUPPORT: SUPPORT_TITLE
} = PAGE_TITLES;

const Login = LoadableMng({
	func: () => import('components/views/authentication/login/Login'),
	delay: 800
});

const NonSubscriber = LoadableMng({
	func: () => import('components/views/non_subscriber')
});

const Main = LoadableMng({
	func: () => import('components/views/Main')
});

const Static = LoadableMng({
	func: () => import('components/templates/StaticContentTemplate')
});

const Regulation = () => <Static title={TERMS_AND_CONDITIONS} />;
const Privacy = () => <Static title={PRIVACY_POLICY_TITLE} />;
const AboutUs = () => <Static title={ABOUT_US_TITLE} />;
const Contact = () => <Static title={CONTACT_TITLE} />;
const Support = () => <Static title={SUPPORT_TITLE} />;

const AppRoutes = () => {
	const { isMobile, mobileType } = useUserAgent();
	const location = useLocation();

	const { signOutLoading } = useSelector(({ auth }) => auth);
	const searchParams = new URLSearchParams(location.search);
	const query = searchParams.get('app') || false;
	const isFromApp = query === 'true' ? true : false;
	const { androidUrl, appleUrl } = getAppStoreUrls();
	const isPrivacyPolicy = location.pathname.includes('privacy');
	const isLoginByQrCode = location.pathname.includes('byQrCode');
	const isMobileDocument = isMobile && isFromApp;
	const isMobileDevicesRedirecting = isMobile && !isFromApp;

	const mobileRedirectMap = {
		ios: appleUrl,
		android: androidUrl
	};

	useEffect(() => {
		if (isLoginByQrCode) {
			return (window.location = mobileRedirectMap[mobileType]);
		}

		if (isMobileDevicesRedirecting) {
			return (window.location = MOBILE_DEVICES_WEBSITE_URL);
		}
	}, [isLoginByQrCode, isMobileDevicesRedirecting, mobileRedirectMap, mobileType]);

	const renderRoutes = () =>
		isMobileDocument ? (
			isPrivacyPolicy ? (
				<Privacy />
			) : (
				<Regulation />
			)
		) : (
			<Switch>
				{signOutLoading && <Redirect to="/" />}
				<Route path={getNavigationPaths(ERROR_404)} component={PageNotFound} />
				<Route path={getNavigationPaths(REGULATION)} component={Regulation} />
				<Route path={getNavigationPaths(PRIVACY_POLICY)} component={Privacy} />
				<Route path={getNavigationPaths(ABOUT)} component={AboutUs} />
				<Route path={getNavigationPaths(CONTACT)} component={Contact} />
				<Route path={getNavigationPaths(SUPPORT)} component={Support} />
				{isFromApp && (
					<Route
						path={getNavigationPaths(REGULATION)}
						component={RegulationRedirect}
					/>
				)}
				<ProtectedUserLogged
					path={getNavigationPaths(LOGIN)}
					component={Login}
				/>
				<Route
					path={getNavigationPaths(NON_SUBSCRIBER)}
					component={NonSubscriber}
				/>
				<Main />
				<Route path="*" component={Error404} />
			</Switch>
		);

	return renderRoutes();
};

export default AppRoutes;
