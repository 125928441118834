import styled, { css } from 'styled-components';
import { rtl } from 'components/styles';
// Import helpers
import { getFontFamily } from 'helpers/rtl';

export const VideoContainer = styled.div`
	position: relative;
	height: 100%;

	${({ isPlayerTv }) =>
		isPlayerTv &&
		css`
			width: ${({ theme: { playerTv }, isFullScreen }) =>
				isFullScreen ? '100%' : `calc(100% - ${playerTv.sidebar.width}rem)`};
			${rtl`margin-right: auto;`};
		`}

	
	cursor: ${({ isFocus }) => (isFocus ? 'default' : 'none')} !important;
	* {
		font-family: ${`${({ isRtl }) => getFontFamily(isRtl)} sans-serif`};
	}

	.shaka-scrim-container {
		background: transparent !important;
	}

	.shaka-text-container span {
		z-index: 1;
		font-size: 3.5rem;
		font-weight: 700;
	}

	.shaka-bottom-controls {
		width: calc(100vw - 460px);
		height: 8.7rem;
		visibility: ${({ isReady }) => (isReady ? 'visible' : 'hidden')};

		${({ isCatchup = false }) =>
			isCatchup &&
			css`
				width: 100%;
				padding: 0 2.5rem 0 25rem;
				height: 4.6rem;
			`}
	}

	.shaka-spinner-container {
		display: none;
	}

	.shaka-seek-bar-container {
		opacity: ${({ isFocus }) => (isFocus ? 1 : 0)} !important;
	}

	.shaka-seek-bar {
		cursor: pointer;
	}

	input.shaka-seek-bar:focus {
		outline: none;
	}

	input.shaka-seek-bar::-moz-focus-outer {
		border: 0;
	}

	/* Special styling for WebKit/Blink */
	input.shaka-seek-bar::-webkit-slider-thumb {
		-webkit-appearance: none;
		cursor: pointer;
		height: 14px;
		width: 14px;
		top: 12.5px;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(255, 255, 255, 0.6);
		border-image: initial;
		background: linear-gradient(rgb(45, 167, 223) 0px, rgb(33, 64, 154) 100%);
	}

	/* All the same stuff for Firefox */
	input.shaka-seek-bar::-moz-range-thumb {
		cursor: pointer;
		height: 14px;
		width: 14px;
		top: 12.5px;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(255, 255, 255, 0.6);
		border-image: initial;
		background: linear-gradient(rgb(45, 167, 223) 0px, rgb(33, 64, 154) 100%);
		outline: none;
	}

	/* All the same stuff for IE */
	input.shaka-seek-bar::-ms-thumb {
		cursor: pointer;
		height: 14px;
		width: 14px;
		top: 12.5px;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(255, 255, 255, 0.6);
		border-image: initial;
		background: linear-gradient(rgb(45, 167, 223) 0px, rgb(33, 64, 154) 100%);
		outline: none;
	}

	.shaka-client-side-ad-container {
		z-index: 9999;
	}
`;

export const ShakaVideo = styled.video`
	width: 100%;
	height: 100%;
	display: block;
	background: black;
`;

export const PlayerFullScreenTarget = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
`;
