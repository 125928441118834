import styled from 'styled-components';

// Import styles
import { borderGradient, flex, rtl } from 'components/styles';

export const Wrapper = styled.div`
	position: absolute;
	${rtl`right: 100%;`};
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	width: 26.4rem;
	height: 32.2rem;
	padding: 1rem;
	background: ${({ theme: { playerTv } }) =>
		playerTv.sidebar.watchlist_modal.bg};
	border-radius: 1rem;
	${({ theme: { playerTv } }) =>
		borderGradient({
			borderSize: 1,
			borderBg: playerTv.sidebar.watchlist_modal.border
		})}
`;

export const InnerWrapper = styled.div`
	padding: 0 2.5rem;
	width: 100%;
	${flex({ fd: 'column', jc: 'flex-start' })};
	gap: 2rem;
`;

export const Description = styled.p`
	font-size: 1.4rem;
	font-weight: ${({ theme: { font } }) => font.light};
	color: ${({ theme: { playerTv } }) =>
		playerTv.sidebar.watchlist_modal.description};
	line-height: 1.6rem;

	span {
		display: block;
		width: 100%;
		font-weight: ${({ theme: { font } }) => font.regular};
	}
`;

export const CloseButton = styled.button`
	cursor: pointer;
	border: none;
	background: none;
	width: 2rem;
	height: 2rem;
	color: ${({ theme: { playerTv } }) =>
		playerTv.sidebar.watchlist_modal.closeButton};
`;
