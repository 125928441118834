import React from 'react';

// Import assets
import { ReactComponent as IconPauseSVG } from 'assets/icons/ic_pause_tv.svg';
import { ReactComponent as IconPlaySVG } from 'assets/icons/ic_play_tv.svg';

// Import styles
import { Button } from './styles';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

export const PlayPauseController = () => {
	const { play, pause, isPaused } = usePlayerTvContext();

	const handleButtonClick = isPaused ? play : pause;

	return (
		<Button onClick={handleButtonClick}>
			{isPaused ? <IconPlaySVG /> : <IconPauseSVG />}
		</Button>
	);
};
