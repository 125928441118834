import React from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

// Import assets
import { ReactComponent as IconCastConnectedSVG } from 'assets/icons/ic_cast_connected.svg';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

// Import styles
import { Wrapper, InnerWrapper, Name } from './styles';

export const CastReceiverName = () => {
	const { t } = useTranslation();
	const { receiverName, isCasting } = usePlayerTvContext();

	return (
		<Wrapper>
			<Transition
				in={isCasting}
				timeout={{ enter: 0, exit: 500 }}
				mountOnEnter
				unmountOnExit
			>
				{(state) => (
					<InnerWrapper className={state} aria-hidden tabIndex="-1">
						<IconCastConnectedSVG />
						<Name>
							{t('player_cast_receiver_info')} {receiverName}
						</Name>
					</InnerWrapper>
				)}
			</Transition>
		</Wrapper>
	);
};
