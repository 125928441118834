import React from 'react';

// Import assets
import { ReactComponent as IconCastSVG } from 'assets/icons/ic_cast.svg';
import { ReactComponent as IconCastConnectedSVG } from 'assets/icons/ic_cast_connected.svg';

// Import components
import { ButtonIcon } from '../styles';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';
export const CastController = () => {
	const { isCasting, isCastAvailable, cast, isReady } = usePlayerTvContext();

	if (!isCastAvailable || !isReady) return null;

	return (
		<ButtonIcon onClick={cast}>
			{isCasting ? <IconCastConnectedSVG /> : <IconCastSVG />}
		</ButtonIcon>
	);
};
