import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { clearSelectedProgram, playerOnCancel } from 'store/actions';

// Import helpers
import { playerCatchStyles, playerStyles } from 'helpers/player';

// Import utilities
import { PlayerFocus } from 'components/utilities';

// Import components
import { Loader } from 'components/elements';
import PlayerContext from './player_context/PlayerContext';
import PlayerSidebar from 'components/elements/organisms/player/player_overlay/player_sidebar/PlayerSidebar';
import { PlayerHeading } from 'components/elements/organisms/player/player_overlay/player_heading/PlayerHeading';

// Import logic
import usePlayer from './usePlayer';

const Player = React.memo(
	({
		isSiedebarVisible = true,
		isLoaded,
		type,
		clearSelectedProgram,
		playerOnCancel,
		isReady,
		isCatchup = false
	}) => {
		const {
			isFullScreen,
			playerParentElement,
			isAutoPlay,
			isError,
			setFullScreen,
			setPlayerParentElement,
			setIsAutoPlay
		} = usePlayer({ type, clearSelectedProgram, playerOnCancel });

		return (
			<PlayerFocus isReady={isReady}>
				{({
					isFocus,
					resetFocus,
					onSidebarMouseEnter,
					onSidebarMouseLeave
				}) => (
					<PlayerWrapper
						isFullScreen={isFullScreen}
						isCatchup={isCatchup}
						className="player-wrapper"
						dir="ltr"
					>
						<EmbededWrapper id={type} ref={(el) => setPlayerParentElement(el)}>
							{isLoaded ? (
								<PlayerContext
									isCatchup={isCatchup}
									setFullScreen={setFullScreen}
									isSiedebarVisible={isSiedebarVisible}
									type={type}
									isFocus={isFocus}
									resetFocus={resetFocus}
									playerParentElement={playerParentElement}
									isAutoPlay={isAutoPlay}
									setIsAutoPlay={setIsAutoPlay}
								/>
							) : (
								<Loader zIndex={100} position="absolute" background={false} />
							)}

							<div
								onMouseEnter={onSidebarMouseEnter}
								onMouseLeave={onSidebarMouseLeave}
							>
								<PlayerHeading isFocus={isFocus} isError={isError} />

								{isSiedebarVisible && (
									<PlayerSidebar
										isFocus={isFocus}
										isFullScreen={isFullScreen}
									/>
								)}
							</div>
						</EmbededWrapper>
					</PlayerWrapper>
				)}
			</PlayerFocus>
		);
	}
);

const PlayerWrapper = styled.div`
	visibility: visible;
	opacity: 1;
	position: relative;
	margin: 0;
	width: 100%;
	height: calc(100vh - ${({ theme }) => theme.nav.height}rem);
	background-color: ${({ theme }) => theme.black};

	${({ isCatchup, isFullScreen }) =>
		isFullScreen && isCatchup ? playerCatchStyles : playerStyles}
`;

const EmbededWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border: 0;
`;

Player.propTypes = {
	isSiedebarVisible: PropTypes.bool,
	isLoaded: PropTypes.bool.isRequired,
	isReady: PropTypes.bool.isRequired,
	isCatchup: PropTypes.bool,
	videoSessionId: PropTypes.string,
	type: PropTypes.string.isRequired,
	clearSelectedProgram: PropTypes.func.isRequired
};

const mapStateToProps = (state, { type }) => {
	const { isLoaded, videoSessionId, isReady } = state[type];
	return {
		isLoaded,
		videoSessionId,
		isReady
	};
};

export default connect(
	mapStateToProps,
	{ clearSelectedProgram, playerOnCancel }
)(Player);
