import React from 'react';
import { bool } from 'prop-types';
import { useSelector } from 'react-redux';

// Import styles
import { Wrapper, Heading, Duration } from './styles';

// Import hooks
import { useProductTranslations } from 'components/utilities';

// Import helpers
import { getDuration } from './helpers';

export const PlayerHeading = ({ isFocus, isError }) => {
	const channel = useSelector(({ selected_program, channels }) =>
		channels.data.lives.find(({ uuid }) => uuid === selected_program.channelID)
	);

	const epgProgrammes = channel?.epgProgrammes;
	const currentLiveIndex = channel?.currentLiveIndex;
	const currentProgramData = epgProgrammes?.[currentLiveIndex];

	const { product: program } = useProductTranslations({
		data: currentProgramData
	});

	if (isError || !isFocus || !program) return null;

	const { title, since, till } = program;
	const duration = getDuration({ since, till });

	return (
		<Wrapper>
			<Heading>{title}</Heading>
			<Duration>{duration}</Duration>
		</Wrapper>
	);
};

PlayerHeading.propTypes = {
	isFocus: bool.isRequired,
	isError: bool.isRequired
};
