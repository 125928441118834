// Import helpers
import {
	getNavigationPaths,
	APP_PATHS_KEY
} from 'components/routes/helpers/paths';

const { MAIN, SECTION_MOVIES, SECTION_SERIES, LIVE } = APP_PATHS_KEY;

export const NAV_LINKS = [
	{
		slug: '',
		name: 'main',
		id: 'main',
		path: MAIN,
		translationSection: 'header_nav'
	},
	// {
	// 	slug: '',
	// 	name: 'channels',
	// 	id: 'channels',
	// 	path: getNavigationPaths(CHANNELS),
	// 	translationSection: 'header_nav'
	// },
	{
		slug: '',
		name: 'tv_shows',
		id: 'tv_shows',
		path: getNavigationPaths(SECTION_SERIES),
		translationSection: 'header_nav'
	},
	{
		slug: '',
		name: 'movies',
		id: 'movies',
		path: getNavigationPaths(SECTION_MOVIES),
		translationSection: 'header_nav'
	},
	// {
	// 	slug: '',
	// 	name: 'epg',
	// 	id: 'epg',
	// 	path: getNavigationPaths(EPG),
	// 	translationSection: 'header_nav'
	// },
	{
		slug: '',
		name: 'home',
		id: 'home',
		path: getNavigationPaths(LIVE),
		translationSection: 'header_nav'
	}
];
