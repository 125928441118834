import React from 'react';
import PropTypes from 'prop-types';

// Import components
import {
	SearchBox,
	InputSearch,
	ButtonClose,
	CloseIconStyled,
	SearchIconStyledLeft
} from './styles';

// Import hooks
import { useTranslation } from 'react-i18next';

// Import context
import { PlayerSidebarContext } from 'components/context/player/PlayerSidebarProvider';

const SidebarSearch = ({ openSearchBox }) => {
	const { t } = useTranslation();
	// Get context
	const { query, setQuery, setCategory } = React.useContext(
		PlayerSidebarContext
	);

	const handleSearchChannel = ({ target: { value } }) => {
		// Set default category
		setCategory();
		// Set search query
		setQuery(value);
	};

	const handleClearSearch = () => {
		// Set reset query
		setQuery();
		// Close search box
		openSearchBox();
	};

	return (
		<SearchBox>
			<InputSearch
				onChange={handleSearchChannel}
				placeholder={t('common_search_placeholder')}
				type="text"
				autoFocus
				value={query}
			/>
			<SearchIconStyledLeft />
			<ButtonClose onClick={handleClearSearch}>
				<CloseIconStyled />
			</ButtonClose>
		</SearchBox>
	);
};

SidebarSearch.propTypes = {
	openSearchBox: PropTypes.func.isRequired
};

export default SidebarSearch;
