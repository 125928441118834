import styled from 'styled-components';

// Import components
import { Button } from 'components/elements';

export const ButtonStyled = styled(Button)`
	height: 100%;
	width: fit-content;
	padding: 0;
	visibility: inherit;

	& > svg {
		height: 2.8rem;
		width: 2.8rem;

		& > path {
			fill: ${({ theme: { sidebar }, isFavourite }) =>
				isFavourite
					? sidebar.favouriteButton.colorActive
					: sidebar.favouriteButton.color};
		}
	}
`;
