import styled, { css } from 'styled-components';
import { rtl } from 'components/styles';
// Import components
import { Progress } from 'components/elements';
import { Paragraph } from 'components/elements';
// Import styles
import { flex } from 'components/styles';

export const Wrapper = styled.div`
	width: 20rem;
`;

export const Content = styled.div`
	${flex({ ai: 'center' })}
	width: 100%;
	gap: 0.8rem;
	padding-right: 0.8rem;
`;

export const LogoBox = styled.div`
	height: 100%;
	width: 6.7rem;
	float: left;
	border: 1px solid
		${({ theme, isSelected }) =>
			isSelected
				? theme.playerTv.sidebar.programTile.borderActive
				: 'transparent'};
	border-radius: 0.4rem;
	background: ${({ theme: { playerTv } }) =>
		playerTv.sidebar.programTile.background};
	${flex()}
`;

export const Figure = styled.figure`
	margin: 0;
	position: relative;
	height: 6.5rem;
	width: 6.7rem;
	background: inherit;
	border-radius: 0.3rem;
	overflow: hidden;
`;

export const Logo = styled.img`
	width: 60%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const Description = styled.div`
	height: 6.7rem;
	padding: 1.2rem;
	width: 90%;
	border-radius: 0.4rem;
	border: 1px solid
		${({ theme, isSelected }) =>
			isSelected
				? theme.playerTv.sidebar.programTile.borderActive
				: 'transparent'};
	color: ${({ theme }) => theme.playerTv.sidebar.programTile.color};
	background: ${({ theme: { playerTv } }) =>
		playerTv.sidebar.programTile.background};
`;

export const Channel = styled.div`
	height: 100%;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	background-color: transparent;
	border-radius: 0.3rem;

	&:hover {
		${LogoBox} {
			background: ${({ theme: { playerTv } }) =>
				playerTv.sidebar.programTile.backgroundHover};
		}
	}

	// Description

	&:hover {
		${Description} {
			background: ${({ theme: { playerTv } }) =>
				playerTv.sidebar.programTile.backgroundHover};
		}
	}
`;

export const ChannelName = styled.p`
	font-size: 1.4rem;
	font-weight: ${({ theme }) => theme.font.medium};
	margin-bottom: 0.8rem;
`;

export const ProgressBarStyled = styled(Progress.Bar)`
	width: 100%;
	margin: 0.4rem 0;
`;

export const Info = styled.div`
	font-size: 1.4rem;
	${flex({ ai: 'center' })}
	height: 100%;
	${({ isUpcoming }) =>
		isUpcoming &&
		css`
			opacity: 0.6;
		`}
`;

export const TimeBox = styled.div`
	${rtl`
	float: left;
	`}
	direction: ltr;
	${flex()}
`;

export const Time = styled.p`
	font-weight: ${({ theme }) => theme.font.light};
`;

export const ParagraphStyled = styled(Paragraph.Ellipsis)`
	max-width: 20.5rem;
	width: 100%;
	font-size: 1.4rem;
	font-weight: ${({ theme }) => theme.font.normal};
`;
