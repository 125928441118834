import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Import helpers
import { getFilteredChannels } from './sidebar_channel/helpers/index';
import { getLivePrograms, getFavouriteChannels } from 'helpers/epg';
import { getChannelName } from 'helpers/languages';
import { checkIsFavourite } from 'helpers/epg';

// Import components
import SidebarChannel from './sidebar_channel/SidebarChannel';

// Import styles
import { ListItem, Wrapper } from './styles';

const SidebarChannels = React.memo(
	(props) => {
		const { isFullScreen, setIsScrolled } = props;
		const { isRtl } = useSelector(({ direction }) => direction);
		const { i18n } = useTranslation();
		const onScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
			const scrollingTrehsholdInPixels = 10;
			const shouldHideShadowBox =
				scrollHeight - clientHeight <= scrollTop + scrollingTrehsholdInPixels;
			setIsScrolled(shouldHideShadowBox);
		};

		const { channelID: channelIdSelected } = useSelector(
			(state) => state.selected_program
		);

		const channels = React.useMemo(() => {
			const { lives, favourites, query, category } = props;
			const livesFiltered = lives.filter(getLivePrograms);
			const filterOptions = {
				lives: livesFiltered,
				category,
				query,
				locale: i18n.language
			};

			let filteredChannels =
				category === 'ulubione'
					? getFavouriteChannels(lives, favourites)
					: getFilteredChannels(filterOptions);

			// Sort by favorite first, then by channel name in ascending order
			filteredChannels.sort((a, b) => {
				return (
					checkIsFavourite(favourites, b.uuid) -
						checkIsFavourite(favourites, a.uuid) ||
					a.title.localeCompare(b.title)
				);
			});

			return filteredChannels;
			// eslint-disable-next-line
		}, [JSON.stringify(props)]);

		const selectedChannelIndex = React.useMemo(
			() => channels.findIndex((channel) => channel.uuid === channelIdSelected),
			[channels, channelIdSelected]
		);

		const channelRow = ({ index, style, isScrolling, isVisible }) => {
			const {
				currentLiveIndex,
				title,
				images,
				uuid,
				epgProgrammes,
				avaiable,
				translations
			} = channels[index];

			const channelName = getChannelName({ title, translations });

			return (
				<ListItem key={uuid} style={style}>
					<SidebarChannel
						images={images}
						channelID={uuid}
						channelName={channelName}
						program={epgProgrammes[currentLiveIndex]}
						nextProgram={epgProgrammes[currentLiveIndex + 1]}
						available={avaiable}
						isScrolling={isScrolling}
						isVisible={isVisible}
						isFullScreen={isFullScreen}
					/>
				</ListItem>
			);
		};

		return (
			<Wrapper isFullScreen={isFullScreen}>
				<AutoSizer>
					{({ height, width }) => (
						<List
							className="player-sidebar-list"
							width={width}
							height={height}
							rowCount={channels.length}
							rowHeight={75}
							rowRenderer={channelRow}
							overScanRowCount={2}
							style={{ direction: isRtl }}
							onScroll={onScroll}
							scrollToAlignment="start"
							scrollToIndex={selectedChannelIndex}
						/>
					)}
				</AutoSizer>
			</Wrapper>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

SidebarChannels.propTypes = {
	lives: PropTypes.array.isRequired,
	favourites: PropTypes.array.isRequired
};

const mapStateToProps = ({
	channels: {
		data: { lives }
	},
	favourites: { data }
}) => ({
	lives,
	favourites: data
});

export default connect(mapStateToProps)(SidebarChannels);
