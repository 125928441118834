import { rtl } from 'components/styles';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	position: absolute;
	z-index: 9999;
	${rtl`right: ${({ theme: { playerTv }, isFocus, isFullScreen }) =>
		isFullScreen && isFocus
			? `calc(${playerTv.sidebar.width}rem + 4rem)`
			: '4.5rem'};`};

	top: 4.5rem;

	${({ isProgramDetails, isFullScreen }) =>
		isProgramDetails &&
		css`
			top: ${isFullScreen ? 11 : 5}rem;
			right: 6.3rem;
		`};
`;

export const WatermarkLogo = styled.img`
	height: 8rem;
	object-fit: contain;
`;
