import React, { createContext } from 'react';

// Import hooks
import { useWatchlistModal } from './useWatchlistModal';

export const PlayerSidebarContext = createContext();

const PlayerSidebarProvider = ({ children }) => {
	const defaultCategory = 'all';

	const [category, setCategory] = React.useState(defaultCategory);
	const [query, setQuery] = React.useState('');

	const {
		showModal,
		closeModal,
		toggleModalNextVisit,
		isModalVisible,
		isModalAvailable,
		isModalNextVisitEnabled
	} = useWatchlistModal();

	const handleSetCategory = (category = defaultCategory) =>
		setCategory(category);

	const handleSetQuery = (query = '') => setQuery(query);

	return (
		<PlayerSidebarContext.Provider
			value={{
				category,
				setCategory: handleSetCategory,
				query,
				setQuery: handleSetQuery,
				isModalVisible,
				showModal,
				closeModal,
				toggleModalNextVisit,
				isModalAvailable,
				isModalNextVisitEnabled
			}}
		>
			{children}
		</PlayerSidebarContext.Provider>
	);
};

export default PlayerSidebarProvider;
