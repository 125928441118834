import styled from 'styled-components';

export const Wrapper = styled.div`
	position: absolute;
	z-index: 1;
	right: 6.3rem;

	top: ${({ isMovedUp, isFullScreen }) =>
		isMovedUp && !isFullScreen ? 5 : 11}rem;
`;

export const WatermarkLogo = styled.img`
	height: 8rem;
	object-fit: contain;
`;
