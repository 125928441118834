import React from 'react';

// Import assets
import { ReactComponent as CheckboxIcon } from 'assets/icons/ic_checkbox_icon_checked.svg';

// Import styles
import {
	CheckboxWrapper,
	HiddenCheckbox,
	Label,
	StyledCheckbox
} from './styles';

export const Checkbox = ({ checked, onChange, children }) => (
	<CheckboxWrapper>
		<HiddenCheckbox checked={checked} onChange={onChange} />
		<StyledCheckbox>
			<CheckboxIcon />
		</StyledCheckbox>
		{children && <Label>{children}</Label>}
	</CheckboxWrapper>
);
