// Import variables
import { ARABIC_SLUGS, ENGLISH_SLUGS, ITEM } from 'helpers/variables';

const {
	MOVIES: MOVIES_AR,
	SERIES: SERIES_AR,
	CHANNELS: CHANNELS_AR,
	LIVE: LIVE_AR
} = ARABIC_SLUGS;
const { CHANNELS: CHANNELS_EN, LIVE: LIVE_EN } = ENGLISH_SLUGS;

const en = {
	main: '/',
	about: '/page/about-us',
	regulation: '/page/regulation',
	rodo: '/page/rodo',
	support: '/page/support',
	register: '/register',
	resetPassword: '/reset-password',
	emailConfirm: '/confirm-email/:hash',
	login: '/login',
	packets: '/packets',
	singlePacket: `/packets/:type/:id/:title`,
	home: '/tv/home',
	programDetails: `/tv/${CHANNELS_EN}/program-details/:id`,
	channels: `/tv/${CHANNELS_EN}`,
	epg: '/tv/epg',
	userDevices: '/user/devices',
	userGeneral: '/user/general',
	userRecordings: '/user/recordings',
	userProducts: '/user/products',
	userSubscriptions: '/user/subscriptions',
	userFavourites: '/user/favourites',
	userPayments: '/user/payments',
	userRegulation: '/user/regulation',
	userWatchlist: '/user/watchlist',
	userEntryTickets: '/user/entry-tickets',
	userDeletingAccount: '/user/deleting-account',
	player: `/vod/watch/:type/${ITEM}/:id`,
	sectionDetails: '/vod/section/:sectionId/:sectionName',
	sectionChannelsDetails: '/vod/section_channels/:sectionId/:sectionName',
	sectionCategoryDetails: '/vod/categories',
	productDetails: `/vod/:type/${ITEM}/:id/:title`,
	sectionMovies: '/vod/movies',
	sectionSeries: '/vod/series',
	sectionKids: '/vod/kids',
	tv: '/tv',
	vod: '/vod',
	user: '/user',
	search: '/search/:query/:category',
	transactionFinalization: '/transaction/:query',
	privacyPolicy: '/page/privacy-policy',
	contact: '/page/contact',
	faq: '/page/faq',
	nonSubscriber: '/non-subscriber',
	codeEvent: '/code',
	error404: '/error/404',
	live: `/tv/${LIVE_EN}`
};

const ar = {
	main: '/',
	about: '/page/about-us',
	regulation: '/page/regulation',
	rodo: '/page/rodo',
	support: '/page/support',
	register: '/register',
	resetPassword: '/reset-password',
	emailConfirm: '/confirm-email/:hash',
	login: '/login',
	packets: '/packets',
	singlePacket: `/packets/:type/:id/:title`,
	home: '/tv/home',
	programDetails: `/tv/${CHANNELS_AR}/program-details/:id`,
	channels: `/tv/${CHANNELS_AR}`,
	epg: '/tv/epg',
	userDevices: '/user/devices',
	userGeneral: '/user/general',
	userRecordings: '/user/recordings',
	userProducts: '/user/products',
	userSubscriptions: '/user/subscriptions',
	userFavourites: '/user/favourites',
	userPayments: '/user/payments',
	userRegulation: '/user/regulation',
	userWatchlist: '/user/watchlist',
	userEntryTickets: '/user/entry-tickets',
	userDeletingAccount: '/user/deleting-account',
	player: `/vod/watch/:type/${ITEM}/:id`,
	sectionDetails: '/vod/section/:sectionId/:sectionName',
	sectionChannelsDetails: '/vod/section_channels/:sectionId/:sectionName',
	sectionCategoryDetails: '/vod/categories',
	productDetails: `/vod/:type/${ITEM}/:id/:title`,
	sectionMovies: `/vod/${MOVIES_AR}`,
	sectionSeries: `/vod/${SERIES_AR}`,
	sectionKids: '/vod/kids',
	tv: '/tv',
	vod: '/vod',
	user: '/user',
	search: '/search/:query/:category',
	transactionFinalization: '/transaction/:query',
	privacyPolicy: '/page/privacy-policy',
	contact: '/page/contact',
	faq: '/page/faq',
	nonSubscriber: '/non-subscriber',
	codeEvent: '/code',
	error404: '/error/404',
	live: `/tv/${LIVE_AR}`
};

const pl = {
	main: '/',
	about: '/strona/o-nas',
	regulation: '/strona/regulamin',
	rodo: '/strona/rodo',
	register: '/rejestracja',
	resetPassword: '/reset-hasla',
	emailConfirm: '/potwierdzenie-email/:hash',
	login: '/logowanie',
	packets: '/pakiety',
	singlePacket: '/pakiety/:type/:id/:title',
	home: '/tv/strona-glowna',
	programDetails: '/tv/kanaly/szczegoly-programu/:id',
	channels: '/tv/kanaly',
	epg: '/tv/epg',
	userDevices: '/uzytkownik/urzadzenia',
	userGeneral: '/uzytkownik/profil',
	userRecordings: '/uzytkownik/nagrania',
	userProducts: '/uzytkownik/aktywne-wypozyczenia',
	userSubscriptions: '/uzytkownik/subskrypcje',
	userFavourites: '/uzytkownik/ulubione',
	userPayments: '/uzytkownik/historia-platnosci',
	userRegulation: '/uzytkownik/regulamin',
	userWatchlist: '/uzytkownik/watchlist',
	userEntryTickets: '/user/entry-tickets',
	userDeletingAccount: '/user/deleting-account',
	player: '/vod/ogladaj/:type/:id',
	sectionDetails: '/vod/sekcja/:sectionId/:sectionName',
	sectionChannelsDetails: '/vod/sekcja_kanaly/:sectionId/:sectionName',
	sectionCategoryDetails: '/vod/kategorie',
	productDetails: '/vod/:type/:id/:title',
	sectionMovies: '/vod/filmy',
	sectionSeries: '/vod/seriale',
	sectionKids: '/vod/dla-dzieci',
	tv: '/tv',
	vod: '/vod',
	user: '/uzytkownik',
	search: '/szukaj/:query/:category',
	transactionFinalization: '/transakcja/:query',
	privacyPolicy: '/strona/polityka-prywatnosci',
	faq: '/strona/faq',
	codeEvent: '/code',
	error404: '/error/404',
	live: '/tv/live'
};

export const appPaths = { en, ar, pl };
