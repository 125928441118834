import styled from 'styled-components';

// Import styles
import { flex, transitionMain } from 'components/styles';

export const InnerWrapper = styled.div`
	position: absolute;
	top: -7rem;
	width: 11rem;
	height: 2.5rem;
	${flex()}
	display: none;
	transform: rotate(270deg);
	background-color: ${({ theme }) => theme.black};
	z-index: 2;
`;

export const VolumeRangeInput = styled.input`
	display: none;
	direction: ltr;
	width: 9rem;
	-webkit-appearance: none;
	margin: 0;
	background: transparent;

	&:focus {
		outline: none;
	}

	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 0.5rem;
		cursor: pointer;
		background: linear-gradient(
			to right,
			white 0%,
			white ${({ value }) => value}%,
			#656565 ${({ value }) => value}%,
			#656565 100%
		);
		border-radius: 0.4rem;
	}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 1.3rem;
		height: 1.3rem;
		border-radius: 50%;
		background: white;
		cursor: pointer;
		margin-top: -0.4rem;
	}

	&::-moz-range-track {
		width: 100%;
		height: 0.5rem;
		cursor: pointer;
		background: #656565;
		border-radius: 0.4rem;
	}

	&::-moz-range-progress {
		background-color: white;
		height: 0.5rem;
		border-radius: 0.4rem;
	}

	&::-moz-range-thumb {
		width: 1.3rem;
		height: 1.3rem;
		border-radius: 50%;
		background: white;
		cursor: pointer;
		border: none;
	}
`;

export const Wrapper = styled.div`
	position: relative;
	cursor: pointer;
	${flex()};
	flex-flow: column;
	${transitionMain()};

	&:hover {
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: -11rem;
			left: 0;
			height: 11rem;
			width: 4rem;
			z-index: 1;
		}

		${InnerWrapper}, ${VolumeRangeInput} {
			display: flex;
		}
	}
`;
