import React from 'react';
import { bool } from 'prop-types';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

// Import components
import { CastController } from './CastController';
import { PlayPauseController } from './PlayPauseController';
import { FullscreenController } from './FullscreenController';
import { VolumeController } from './VolumeController';

// Import styles
import { Wrapper, SettingsWrapper } from './styles';

const PlayerControllers = ({ isFocus, isFullScreen }) => {
	const { isReady } = usePlayerTvContext();

	return (
		isReady && (
			<Wrapper isFocus={isFocus} isFullScreen={isFullScreen}>
				<PlayPauseController />

				<SettingsWrapper>
					<CastController />
					<VolumeController />
					<FullscreenController />
				</SettingsWrapper>
			</Wrapper>
		)
	);
};

PlayerControllers.propTypes = {
	isFocus: bool.isRequired,
	isFullScreen: bool.isRequired
};

export default PlayerControllers;
